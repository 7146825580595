function getLeft(left, eWidth, offsetX) {
  if (left) {
    if (window.innerWidth - offsetX - 20 > eWidth) {
      return offsetX + 20;
    }if (window.innerWidth > eWidth) {
      return window.innerWidth - eWidth;
    }
  } else {
    if (eWidth < offsetX - 20) {
      return offsetX - eWidth - 20;
    }
      return 0;
  }

  return 0;
}

function getTop(offset, dimensions) {
  const wHeight = window.innerHeight;

  if (dimensions.y >= wHeight) {
    return 0;
  }

  let percentage = (offset.y / wHeight) * 100;

  percentage = percentage > 100 ? 100 : percentage;

  return (wHeight / 100) * percentage - (dimensions.y / 100) * percentage;
}

function move(left, element, data) {
  const offset = data.offset;
  const dimensions = data.dimensions;

  element.style.left = `${getLeft(left, element.clientWidth, offset.x)}px`;
  element.style.top = `${getTop(offset, dimensions)}px`;

  return false;
}

export function getMove() {
  if (window.requestAnimationFrame) {
    return (left, element, data) => {
      window.requestAnimationFrame(() => {
        move(left, element, data);
      });
    };
  }

  return (left, element, data) => {
    move(left, element, data);
  };
}

export function getType() {
  if (this.data.force) {
    this.data.extension = this.data.force.extension;
    return this.data.force.type;
  }

  this.data.extension = this.data.src.split('.').pop().toLowerCase();

  // Image formats
  if (['jpg', 'jpeg', 'gif', 'png', 'ico', 'svg', 'bmp', 'webp'].includes(this.data.extension)) {
    return 0;
  }
  // Video formats - extended to include all supported formats
  if (['mp4', 'webm', 'ogg', 'ogv', 'mov', 'mkv', 'ts', 'm4v', '3gp', 'avi', 'wmv', 'flv'].includes(this.data.extension)) {
    return 1;
  }

  return null;
}

export function createContainer() {
  const container = document.createElement('div');

  container.className = 'preview-container';

  const styles = {
    'pointer-events': 'none',
    position: 'fixed',
    visibility: 'hidden',
    'z-index': '9999',
    top: '-9999px',
    left: '-9999px',
    'max-width': '100vw',
    'max-height': '100vh',
  };

  Object.keys(styles).forEach((key) => {
    container.style[key] = styles[key];
  });

  return container;
}

function encodeUrl(input) {
  return this.options.encodeAll ? input.replace('#', '%23').replace('?', '%3F') : encodeURI(input);
}

function isAudible(video) {
  if (typeof video.webkitAudioDecodedByteCount !== 'undefined') {
    if (video.webkitAudioDecodedByteCount > 0) {
      return true;
    }
      return false;
  }if (typeof video.mozHasAudio !== 'undefined') {
    if (video.mozHasAudio) {
      return true;
    }
      return false;
  }if (typeof video.audioTracks !== 'undefined') {
    if (video.audioTracks?.length) {
      return true;
    }
      return false;
  }
    return false;

  return false;
}

export function loadImage(src, callback) {

  const img = document.createElement('img');

  this.currentElement = img;

  img.style['max-width'] = 'inherit';
  img.style['max-height'] = 'inherit';

  img.src = encodeUrl.call(this, src);

  this.timers.load = setInterval(() => {
    if (!this.active) {
      callback(false);

      return;
    }

    const w = img.naturalWidth;
    const h = img.naturalHeight;

    if (w && h) {
      if (this.data.on.hasOwnProperty('onLoaded')) {
        try {
          this.data.on.onLoaded({
            loaded: true,
            type: 'IMAGE',
            audible: false,
            element: img,
            src: src,
          });
        } catch (error) {
          console.error(error);
        }
      }

      clearInterval(this.timers.load);

      callback(img, [w, h]);
    }
  }, 30);
}

export function loadVideo(src, callback) {
  
  try {
    // Try using VidStack for video playback
    const { MediaPlayer } = require('vidstack');
    
    // Create VidStack container
    const playerContainer = document.createElement('div');
    playerContainer.className = 'vidstack-player-container preview-player';
    
    // Create the media player element
    const player = document.createElement('media-player') as any;
    player.muted = true;
    player.loop = true;
    player.autoplay = true;
    player.style.width = '100%';
    player.style.height = '100%';
    player.style.maxWidth = 'inherit';
    player.style.maxHeight = 'inherit';
    
    // Get MIME type mapping
    const mimeTypeMap = {
      // Common web formats
      'mp4': 'mp4',
      'webm': 'webm',
      'ogg': 'ogg',
      'ogv': 'ogg',
      // Apple formats
      'mov': 'mp4',
      'm4v': 'mp4',
      // Other formats
      'mkv': 'x-matroska',
      'ts': 'mp2t',
      '3gp': '3gpp',
      'avi': 'x-msvideo',
      'wmv': 'x-ms-wmv',
      'flv': 'x-flv'
    };
    
    const isVideoFormat = ['mp4', 'webm', 'ogg', 'ogv', 'mov', 'mkv', 'ts', 'm4v', '3gp', 'avi', 'wmv', 'flv'].includes(this.data.extension);
    const mimeType = mimeTypeMap[this.data.extension] || 
                   (isVideoFormat ? this.data.extension : 'mp4');
    
    const primaryMimeType = `video/${mimeType}`;
    
    // Create source elements based on file type
    let sourceElements = '';
    
    // For .ts files, provide multiple source options
    if (this.data.extension === 'ts') {
      sourceElements = `
        <source src="${encodeUrl.call(this, src)}" type="video/mp2t" />
        <source src="${encodeUrl.call(this, src)}" type="video/mp4" />
        <source src="${encodeUrl.call(this, src)}" type="video/x-mpegts" />
        <source src="${encodeUrl.call(this, src)}" />
      `;
    } else {
      // For other formats, provide primary type and a fallback with no type
      sourceElements = `
        <source src="${encodeUrl.call(this, src)}" type="${primaryMimeType}" />
        <source src="${encodeUrl.call(this, src)}" />
      `;
    }
    
    // Set player inner HTML
    player.innerHTML = `
      <media-provider>
        ${sourceElements}
      </media-provider>
    `;
    
    // Add player to container
    playerContainer.appendChild(player);
    
    // Store reference to current element
    this.currentElement = player;
    
    // Initialize the player
    const mediaPlayer = new MediaPlayer({
      element: player,
      src: {
        src: encodeUrl.call(this, src),
        type: primaryMimeType
      }
    });
    
    // Event handler for metadata loaded
    player.addEventListener('loaded-metadata', (event) => {
      if (!this.active) {
        callback(false);
        return;
      }
      
      if (this.data.on.hasOwnProperty('onLoaded')) {
        try {
          this.data.on.onLoaded({
            loaded: true,
            type: 'VIDEO',
            audible: false, // Preview is always muted
            element: player,
            src: src,
          });
        } catch (error) {
          console.error(error);
        }
      }
      
      if ((player as any).videoWidth && (player as any).videoHeight) {
        callback(player, [(player as any).videoWidth, (player as any).videoHeight]);
      }
    });
    
    // Fallback timeout in case metadata event doesn't fire
    setTimeout(() => {
      if (!this.active) {
        return;
      }
      
      if ((player as any).videoWidth && (player as any).videoHeight) {
        callback(player, [(player as any).videoWidth, (player as any).videoHeight]);
      } else {
        // If still no dimensions, use some defaults
        callback(player, [640, 360]);
      }
    }, 500);
    
    return player;
  } catch (error) {
    console.error('Failed to load video with VidStack, falling back to HTML5 video:', error);
    
    // Fallback to standard HTML5 video if VidStack fails
    const video = document.createElement('video');
    const source = video.appendChild(document.createElement('source'));
    
    this.currentElement = video;
    
    ['muted', 'loop', 'autoplay'].forEach((key) => {
      video[key] = true;
    });
    
    // Map each extension to its correct MIME type
    const mimeTypeMap = {
      // Common web formats
      'mp4': 'mp4',
      'webm': 'webm',
      'ogg': 'ogg',
      'ogv': 'ogg',
      // Apple formats
      'mov': 'mp4',
      'm4v': 'mp4',
      // Other formats
      'mkv': 'x-matroska',
      'ts': 'mp2t',
      '3gp': '3gpp',
      'avi': 'x-msvideo',
      'wmv': 'x-ms-wmv',
      'flv': 'x-flv'
    };
    
    // Safely get MIME type or use a safe default for video playback
    const isVideoFormat = ['mp4', 'webm', 'ogg', 'ogv', 'mov', 'mkv', 'ts', 'm4v', '3gp', 'avi', 'wmv', 'flv'].includes(this.data.extension);
    const mimeType = mimeTypeMap[this.data.extension] || 
                    (isVideoFormat ? this.data.extension : 'mp4');
    
    // For .ts files, add multiple source elements to try different formats in all browsers
    if (this.data.extension === 'ts') {
      console.log('Using enhanced handling for .ts files in hover preview');
      
      // Set the primary source as MP4 (best compatibility)
      source.type = 'video/mp4';
      
      // Add additional sources with different MIME types as fallbacks
      const source2 = video.appendChild(document.createElement('source'));
      source2.type = 'video/mp2t';
      source2.src = encodeUrl.call(this, src);
      
      // Try as x-mpegts (sometimes recognized)
      const source3 = video.appendChild(document.createElement('source'));
      source3.type = 'video/x-mpegts';
      source3.src = encodeUrl.call(this, src);
      
      // Last option - no type specified, let browser detect
      const source4 = video.appendChild(document.createElement('source'));
      source4.src = encodeUrl.call(this, src);
    } else {
      // Standard handling for other formats
      source.type = `video/${mimeType}`;
    }
    
    source.src = encodeUrl.call(this, src);
    
    video.style['max-width'] = 'inherit';
    video.style['max-height'] = 'inherit';
    
    video.onloadeddata = () => {
      if (!this.active) {
        callback(false);
        
        return;
      }
      
      if (this.data.on.hasOwnProperty('onLoaded')) {
        try {
          this.data.on.onLoaded({
            loaded: true,
            type: 'VIDEO',
            audible: isAudible(video),
            element: video,
            src: src,
          });
        } catch (error) {
          console.error(error);
        }
      }
    };
    
    video.onloadedmetadata = (event) => {
      const eventTarget: HTMLVideoElement = event.target as HTMLVideoElement;
      
      if (!this.active) {
        callback(false);
        
        return;
      }
      
      callback(video, [eventTarget.videoWidth, eventTarget.videoHeight]);
    };
    
    return video;
  }
}
