/** Vendors */
import cookies from 'js-cookie';
import { formatDate } from '../../vendors/date/date';
/** Config */
import { config, user } from '../../config/config';
import data from '../../config/data';
/** Modules */
import { eventHooks } from '../../modules/event-hooks';
/** Helpers */
import {
  DOM,
  generateLinks,
  clipboardCopy,
  comparer,
  getReadableSize,
  capitalize,
} from '../../helpers';

/** Types */
import type { MComponentMain, TUserClient, TOptimizeRowItem } from '../../types';

/* References */
const selector = data.instances.selector;

/* Create main object */
const main: MComponentMain.TCapsule = {
  menu: {
    create: (): HTMLElement => {
      const menu = DOM.new('div', {
        class: 'menu',
      });

      ['gallery', 'copy', 'settings'].forEach((id: string) => {
        const item = DOM.new('div', {
          id: id,
          text: id === 'copy' ? 'Copy Links' : capitalize(id),
        });
        menu.appendChild(item);
      });

      return menu;
    },
    toggle: (state: boolean | null = null): boolean => {
      const menu = document.querySelector('.menu') as HTMLElement;
      if (!menu) return false;

      if (state === null) {
        state = menu.style.display === 'none';
      }

      menu.style.display = state ? 'block' : 'none';
      return state;
    },
  },
  sort: {},
  overlay: {},
  dates: {},
};

/**
 * Get UTC offset of client
 */
main.dates.offsetGet = (): number => {
  return new Date().getTimezoneOffset();
};

/**
 * Formats seconds to an 'ago' string
 */
main.dates.formatSince = (seconds: number) => {
  if (seconds === 0 || seconds < 0) {
    return seconds === 0 ? 'Now' : false;
  }

  const t: {
    [key: string]: number;
  } = {
    year: 31556926,
    month: 2629743,
    week: 604800,
    day: 86000,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  const keys: Array<string> = Object.keys(t);
  const count: number = keys.length - 1;
  let value: string | boolean = false;

  for (let index = 0; index < keys.length; index++) {
    const key: string = keys[index];

    if (seconds <= t[key]) continue;

    const n: string | null = count >= index + 1 ? keys[index + 1] : null;
    const f: number = Math.floor(seconds / t[key]);
    const s: number = n ? Math.floor((seconds - f * t[key]) / t[n]) : 0;

    value =
      `${f} ${key}${f === 1 ? '' : 's'}${s > 0 ? ` and ${s} ${n}${s === 1 ? '' : 's'}` : ''} ago`;

    break;
  }

  return value;
};

main.dates.apply = (offset: MComponentMain.TDateOffset, format = true) => {
  const dateFormat: Array<string> = config.get('format.date');
  const dateSelector = 'tr.directory > td:nth-child(2), tr.file > td[data-raw]:nth-child(2)';

  (selector.use('TABLE') as HTMLElement)
    .querySelectorAll(dateSelector)
    .forEach((item: HTMLTableCellElement) => {
      const timestamp: number = Math.floor(
        new Date(item.getAttribute('data-raw').split(' +')[0]).getTime() / 1000
      );

      if (timestamp) {
        item.setAttribute('data-raw', timestamp.toString());
      }

      const since: string | boolean = main.dates.formatSince(
        Math.floor(Date.now() / 1000) - timestamp + main.dates.offsetGet() * 60
      );

      const span: HTMLSpanElement =
        format === true ? DOM.new('span') : item.querySelector(':scope > span');

      /* Update the date formats if the offset has been changed or set for the first time */
      if (timestamp && format === true) {
        dateFormat.forEach((f: string, index: number) => {
          if (index <= 1) {
            const element: HTMLElement = DOM.new('span', {
              text: formatDate(f, timestamp),
            });

            span.appendChild(element);
          }
        });

        item.innerHTML = span.innerHTML;
      }

      if (since) {
        span.setAttribute('title', `${since} (UTC${(offset.hours > 0 ? '+' : '') + offset.hours})`);
        span.setAttribute('data-time', `${timestamp}`);
      }
    });

  const infoSelector =
    'div.topBar > .directoryInfo div[data-count="files"], div.topBar > .directoryInfo div[data-count="directories"]';

  document.body.querySelectorAll(infoSelector).forEach((item) => {
    if (item.hasAttribute('data-raw')) {
      item.setAttribute(
        'title',
        `Newest: ${formatDate(dateFormat[0], Number.parseInt(item.getAttribute('data-raw')))}`
      );
    }
  });
};

/**
 * Gets client timezone offset and sets hover timestamps
 */
main.dates.load = () => {
  const offset: number = main.dates.offsetGet();
  const client: TUserClient = user.get();
  const update: boolean = client.timezone_offset !== offset;

  /* Only update if offset is changed or unset */
  if (update) {
    /* Update client's offset */
    client.timezone_offset = offset;

    /* Save client */
    user.set(client);
  }

  const offsetData: MComponentMain.TDateOffset = {
    minutes: offset > 0 ? -Math.abs(offset) : Math.abs(offset),
  };

  offsetData.hours = offsetData.minutes / 60;
  offsetData.seconds = offsetData.minutes * 60;

  main.dates.apply(offsetData, update);
};

/**
 * Load sorting
 */
main.sort.load = () => {
  if (config.exists('sorting') && config.get('sorting.enabled')) {
    const sortingTypes = config.get('sorting.types');

    if (sortingTypes === 0 || sortingTypes === 1) {
      const asc = config.get('sorting.order')  === 'asc';
      let index = null;

      switch (config.get('sorting.sortBy')) {
        case 'name':
          index = 0;
          break;
        case 'modified':
          index = 1;
          break;
        case 'size':
          index = 2;
          break;
        case 'type':
          index = 3;
          break;
        default:
          index = null;
      }

      if (index !== null) {
        const tableCell: MComponentMain.ISortRow = document
          .querySelectorAll('table th span[sortable]')
          [index].closest('th');

        if (tableCell) {
          tableCell.asc = asc;

          const indicator: HTMLElement = tableCell.querySelector(':scope > span.sortingIndicator');

          if (indicator) {
            indicator.classList.add(asc ? 'down' : 'up', 'visible');
          }
        }
      }
    }
  }
};

/**
 * Hides the overlay
 */
main.overlay.hide = (callback = null): void => {
  type collectionItem = {
    element: HTMLElement;
    f: () => void;
  };

  let i = 0;

  const collection: Array<collectionItem> = [];

  // Removed filterContainer since search bar is always visible
  /*
  collection.push({
    element: document.body.querySelector(":scope > div.filterContainer"),
    f: data.components.filter.toggle,
  });
  */

  collection.push({
    element: document.body.querySelector(':scope > div.menu'),
    f: main.menu.toggle,
  });

  collection.forEach((obj: collectionItem) => {
    if (obj.element && obj.element.style.display !== 'none') {
      obj.f();

      i++;
    }
  });

  if (callback) {
    callback(i > 0);
  }
};

/**
 * Gets the current table items
 */
main.getTableItems = () => {
  const items: ReturnType<MComponentMain.TCapsule['getTableItems']> = [];
  let totalSize = 0;

  const calculateAndUpdateTotalSize = () => {
    const totalSizeElement = document.querySelector('[data-count="total-size"]');
    if (totalSizeElement) {
      const sizeObj = getReadableSize(config.get('format.sizes'), totalSize);
      totalSizeElement.innerHTML = `Total Size: <span class="size-text">${sizeObj.value}<span class="size-unit">${sizeObj.unit}</span></span>`;
      totalSizeElement.setAttribute('title', `Total size of all files: ${sizeObj.value}${sizeObj.unit}`);
    }
  };

  if (data.instances.optimize.main.enabled) {
    const [activeRows]: [Array<TOptimizeRowItem>] = data.instances.optimize.main.getActiveData();

    for (let i = 0; i < activeRows.length; i++) {
      if (i === 0) continue;

      const row: TOptimizeRowItem = activeRows[i];
      const anchor: HTMLElement = row.children[0].children[0] as HTMLElement;

      if (anchor.classList.contains('preview')) {
        const size = row.children[2].getAttribute('data-raw');
        totalSize += Number.parseInt(size, 10) || 0;
        items.push({
          url: anchor.getAttribute('href'),
          name: row.children[0].getAttribute('data-raw'),
          size: row.children[2].textContent,
        });
      }
    }
  } else {
    const previews: NodeListOf<HTMLAnchorElement> = (
      selector.use('TABLE') as HTMLElement
    ).querySelectorAll(':scope > tbody > tr.file:not(.filtered) > td:first-child > a.preview');

    previews.forEach((element: HTMLAnchorElement) => {
      const parent = element.parentNode as HTMLElement;
      const container = parent.parentNode as HTMLElement;
      const url = element.getAttribute('href');

      if (typeof url !== 'undefined') {
        const size = container.querySelector('td:nth-child(3)').getAttribute('data-raw');
        totalSize += Number.parseInt(size, 10) || 0;
        const sizeObj = getReadableSize(config.get('format.sizes'), Number.parseInt(size, 10) || 0);
        items.push({
          url: url,
          name: parent.getAttribute('data-raw'),
          size: `<span class="size-text">${sizeObj.value}<span class="size-unit">${sizeObj.unit}</span></span>`,
        });
      }
    });
  }

  calculateAndUpdateTotalSize();

  return items;
};

// Call getTableItems when the page loads
window.addEventListener('load', () => {
  main.getTableItems();
});

/**
 * Sorts a column
 */
main.sortTableColumn = (target: HTMLTableCellElement) => {
  const parent: HTMLTableCellElement = target.closest('th');
  const column: MComponentMain.ISortRow = !(target.tagName === 'TH') ? parent : target;
  const columnIndex: number = DOM.getIndex(column);

  const rows: {
    directories: Array<HTMLElement>;
    files: Array<HTMLElement>;
  } = {
    directories: Array.from(
      (selector.use('TABLE') as HTMLElement).querySelectorAll('tbody > tr.directory')
    ),
    files: Array.from((selector.use('TABLE') as HTMLElement).querySelectorAll('tbody > tr.file')),
  };

  if (data.instances.optimize.main.enabled) {
    data.instances.optimize.main.sortRows(columnIndex, column.asc ? 'desc' : 'asc');
  } else {
    const sortingTypes: number = config.get('sorting.types');

    if (sortingTypes === 0 || sortingTypes === 1) {
      rows.files.sort(comparer(columnIndex));
      if (column.asc) {
        rows.files.reverse();
      }
    }
  }

  column.asc = !column.asc;

  document.body
    .querySelectorAll(
      ':scope > div.tableContainer > table > \
		thead > tr > th span.sortingIndicator'
    )
    .forEach((indicator) => {
      indicator.classList.remove('up', 'down', 'visible');
    });

  parent
    .querySelector(':scope > span.sortingIndicator')
    .classList.add(column.asc ? 'up' : 'down', 'visible');

  const client: TUserClient = user.get();

  /* Set client sorting settings */
  client.sort.ascending = column.asc ? 1 : 0;
  client.sort.row = columnIndex;

  if (columnIndex <= 2) {
    /* Remove any conflicting cookies */
    cookies.remove('sort', { path: '' });
    cookies.remove('order', { path: '' });

    /* Store as separate cookies for Caddy to handle */
    cookies.set('sort', ['name', 'time', 'size'][columnIndex > 2 ? 0 : columnIndex], {
      sameSite: 'lax',
      expires: 365,
    });

    cookies.set('order', client.sort.ascending ? 'asc' : 'desc', {
      sameSite: 'lax',
      expires: 365,
    });
  }

  /* Save client */
  user.set(client);

  if (!data.instances.optimize.main.enabled) {
    const tableBody = (selector.use('TABLE') as HTMLElement).querySelector('tbody');

    Object.keys(rows).forEach((key: string) => {
      rows[key].forEach((item: HTMLElement) => {
        tableBody.append(item);
      });
    });
  }

  data.sets.refresh = true;
  data.sets.selected = null;
};

const componentMain = main;

export { componentMain };
