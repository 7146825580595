/** Config */
import data from '../../config/data';
import { config, user } from '../../config/config';
/** Helpers */
import { DOM, capitalize, checkNested, getNestedPath } from '../../helpers';
/** Modules */
import { eventHooks } from '../../modules/event-hooks';
import { log } from '../../modules/logger';

/** Types */
import type { MComponentSettings, TUserClient } from '../../types';

/**
 * Structure functions (DOM)
 */
const create: MComponentSettings.TCreateCapsule = {};

/**
 * Update functions for settings which require live updating
 */
const update: MComponentSettings.TUpdateCapsule = {
  gallery: {},
};

/**
 * Handling of options
 */
const options: MComponentSettings.TOptionsCapsule = {};

/**
 * Creates a setting option
 */
create.option = (
  element: HTMLElement,
  text: string,
  options: {
    [key: string]: any;
  } = {},
  title: string = null
) => {
  if (Object.prototype.hasOwnProperty.call(options, 'class')) {
    options.class = `option ${options.class}`;
  }

  const wrapperAttributes = Object.assign(
    {
      class: 'option',
    },
    options
  );

  const textAttributes: {
    class: string;
    text: string;
    title?: string;
  } = {
    class: 'option-text',
    text: text,
  };

  if (title) {
    textAttributes.title = title;
  }

  /** Create wrapper */
  const wrapper = DOM.wrap(DOM.wrap(element, 'div'), 'div', wrapperAttributes);

  /** Add to wrapper */
  wrapper.prepend(DOM.new('div', textAttributes) as HTMLElement);

  return wrapper;
};

/**
 * Creates a section
 */
create.section = (id: string, header: string = null) => {
  const container: HTMLElement = DOM.new('div', {
    class: 'section',
    'data-key': id,
  });

  container.appendChild(
    DOM.new('div', {
      class: 'header',
      text: header ? header : capitalize(id),
    })
  );

  return container;
};

/**
 * Creates a select option
 *
 * Set options['data-key'] to override section key
 */
create.select = (
  values: Array<{
    value: string;
    text: string;
  }>,
  options: object = {},
  selected: any = null
) => {
  const element = DOM.new('select', options) as MComponentSettings.TIndexElement;

  values
    .map(
      (
        value: {
          [key: string]: string;
        },
        index: number
      ) => {
        value.text = capitalize(value.text);

        const option: HTMLOptionElement = DOM.new('option', value) as HTMLOptionElement;

        if (selected !== null) {
          if (selected(option, index, element) === true) {
            option.selected = true;
            element.selectedIndex = index;
          }
        }

        return option;
      }
    )
    .forEach((value: HTMLElement) => {
      element.appendChild(value);
    });

  return element;
};

/**
 * Creates a checkbox element
 */
create.check = (options, selected = null) => {
  const checked: boolean = selected !== null ? selected() : false;

  if (checked) {
    options.checked = '';
  }

  const checkbox = DOM.new(
    'input',
    Object.assign(options, {
      type: 'checkbox',
    })
  ) as HTMLInputElement;

  checkbox.checked = checked;

  return checkbox;
};

/**
 * Gallery update
 */
update.gallery.listAlignment = (alignment: number) => {
  if (data.instances.gallery) {
    /** Get any active gallery container */
    const parent = document.body.querySelector(':scope > div.rootGallery > div.galleryContent');

    /** Apply list alignment to container */
    parent.classList[alignment === 0 ? 'remove' : 'add']('reversed');

    /** Get list and media container */
    const detached: HTMLDivElement = parent.querySelector(':scope > div.list');
    const media: HTMLDivElement = parent.querySelector(':scope > div.media');

    /** Remove list */
    parent.querySelector(':scope > div.list').remove();

    /** Insert aligned list */
    media.parentNode.insertBefore(detached, alignment === 1 ? media : media.nextSibling);

    /** Apply options to gallery directly */
    data.instances.gallery.options.list.reverse = alignment !==0;
  }
};

/**
 * Update fit content option
 */
update.gallery.fitContent = (value: boolean) => {
  if (data.instances.gallery) {
    /** Set option directly in active gallery instance */
    data.instances.gallery.options.fitContent = value;

    /* Get gallery wrapper */
    const wrapper: HTMLDivElement = document.body.querySelector(
      'div.rootGallery > div.galleryContent > div.media > div.wrapper'
    );

    if (wrapper && value) {
      wrapper.classList.add('fill');

      /* Force height recalculation */
      data.sets.refresh = true;
      data.sets.selected = null;
    } else if (wrapper) {
      wrapper.classList.remove('fill');

      /* Reset dimensions of wrapper elements */
      ['.cover', '.cover img', 'video'].forEach((selector: string) => {
        DOM.style.set(wrapper.querySelector(selector), {
          height: '',
          width: '',
        });
      });
    }
  }
};

/**
 * Update gallery autoplay option
 *
 * @param value new autoplay state
 */
update.gallery.autoplay = (value: boolean) => {
  if (data.instances.gallery) {
    /** Set option directly in active gallery instance */
    data.instances.gallery.options.autoplay = value;
  }
};

/**
 * Gathers set options
 *
 * @param container settings container
 * @returns {object} object containing set options
 */
options.gather = (container: HTMLElement) => {
  const gathered: MComponentSettings.TGathered = {};

  /* Gather settings elements */
  const elements: NodeListOf<MComponentSettings.TIndexElement> = container.querySelectorAll(
    'select, input[type="checkbox"]'
  );

  /* Iterate over elements, get settings */
  elements.forEach((element: MComponentSettings.TIndexElement) => {
    if (element.hasAttribute('name')) {
      const id: string = element.getAttribute('name');

      /** Get section identifier */
      const section: string = element.hasAttribute('data-key')
        ? element.getAttribute('data-key')
        : element.closest('.section').getAttribute('data-key');

      if (!Object.prototype.hasOwnProperty.call(gathered, section)) {
        gathered[section] = {};
      }

      if (element.tagName === 'SELECT') {
        const setValue = element.selectedIndex;
        gathered[section][id] = setValue;
      } else if (
        element.tagName === 'INPUT' &&
        element.getAttribute('type').toUpperCase() === 'CHECKBOX'
      ) {
        gathered[section][id] = element.checked;
      }
    }
  });

  return gathered;
};

/**
 * Applies the settings passed to the function
 *
 * @param setData settings to apply
 * @param client user client instance
 * @returns {object} an object containing the changed settings
 */
options.set = (setData: object, client: TUserClient) => {
  client = client || user.get();

  Object.keys(setData).forEach((key) => {
    const isMain: boolean = key === 'main';

    if (!isMain && !Object.prototype.hasOwnProperty.call(client, key)) {
      client[key] = {};
    }

    Object.keys(setData[key]).forEach((option) => {
      const value = setData[key][option];

      /** Check if the option has changed, and if so, flag it for updating */
      const changed: boolean = isMain ? client[option] !== value : client[key][option] !== value;

      /** Recreate object - set changed state and value */
      setData[key][option] = {
        value,
        changed,
      };

      if (isMain) {
        client[option] = value;
      } else {
        client[key][option] = value;
      }

      if (changed) {
        /* Call any live updating functions for the changed setting */
        if (isMain && Object.prototype.hasOwnProperty.call(update, option)) {
          update[option](value);
        } else if (checkNested(update, key, option)) {
          update[key][option](value);
        }
      }
    });
  });

  log('settings', 'Set settings:', setData);

  /** Save settings to client */
  user.set(client);

  return setData;
};

export class componentSettings {
  private client: TUserClient;

  private boundEvents: {
    selector?: any;
    events?: Array<string>;
  };

  constructor() {
    this.loadSettings();
    return this;
  }

  private loadSettings = (): void => {
    const savedSettings = localStorage.getItem('userSettings');
    if (savedSettings) {
      const settings = JSON.parse(savedSettings);
      options.set(settings, user.get());
    }
  };

  /**
   * Apply settings (gather and set settings, then close menu)
   */
  apply = (element: HTMLElement, client: TUserClient): void => {
    client = client || user.get();

    const setData = options.gather(element);
    options.set(setData, client);

    // Save settings to local storage
    localStorage.setItem('userSettings', JSON.stringify(setData));

    /** Call functions on settings applied */
    data.components.settings.close();
    data.layer.main.update();
  };

  /**
   * Close settings menu
   */
  close = (): void => {
    /** Remove events */
    Object.keys(this.boundEvents).forEach((eventId: string) => {
      const { selector, events } = this.boundEvents[eventId];

      /** Unlisten to events */
      eventHooks.unlisten(selector, events, eventId);
    });

    this.boundEvents = {};

    /** Remove settings elements */
    document.body
      .querySelectorAll(':scope > div.focusOverlay, :scope > div.settingsContainer')
      .forEach((element) => element.remove());
  };

  getSectionGallery = (section = create.section('settings'), settings = 0) => {
    if (!config.get('mobile')) {
      section.append(
        create.option(
          create.select(
            ['right', 'left'].map((alignment: string) => {
              return {
                value: `align-${alignment}`,
                text: alignment,
              };
            }),
            {
              name: 'listAlignment',
            },
            (option: any, index: number) => {
              return index === this.client.gallery.listAlignment;
            }
          ),
          data.text.settingsLabels.galleryListAlignment.text
        )
      );

      settings++;
    }

    const sets = [];

    sets.push([
      data.text.settingsLabels.galleryVideoAutoplay.text,
      'autoplay',
      data.text.settingsLabels.galleryVideoAutoplay.description,
    ]);

    sets.push([
      data.text.settingsLabels.galleryFitContent.text,
      'fitContent',
      data.text.settingsLabels.galleryFitContent.description,
    ]);

    sets.forEach((e) => {
      const [label, key, description] = e;

      if (key === 'linkCopyFormat') {
        const options = [
          { value: 'wget', text: 'wget' },
          { value: 'aria2', text: 'aria2' },
          { value: 'axel', text: 'axel' },
          { value: 'curl', text: 'curl' },
          { value: 'plain', text: 'Plain URLs' },
        ];

        section.append(
          create.option(
            create.select(
              options,
              { name: key },
              (option) => option.value === config.get('linkCopyFormat')
            ),
            label,
            { class: 'interactable' },
            description
          )
        );
      } else {
        section.append(
          create.option(
            create.check(
              {
                name: key,
              },
              () => {
                return checkNested(this.client, 'gallery', key)
                  ? this.client.gallery[key]
                  : config.get(`gallery.${key}`);
              }
            ),
            label,
            {
              class: 'interactable',
            },
            description
          )
        );
      }

      settings++;
    });

    return {
      settings,
      section,
    };
  };

  removeOnUnbind = ({
    selector,
    events,
    id,
  }: {
    selector: HTMLElement;
    events: string | string[];
    id: string;
  }) => {
    this.boundEvents[id] = {
      selector,
      events,
    };
  };

  /**
   * Create and show the settings menu
   */
  show = () => {
    if (document.querySelector('.settingsContainer')) {
      return;
    }

    this.client = user.get();
    this.boundEvents = {};

    const container = this.createSettingsContainer();
    document.body.appendChild(container);

    this.attachEventListeners(container);
    this.makeDraggable(container);
  };

  private createSettingsContainer = () => {
    const container = DOM.new('div', {
      class: 'settingsContainer',
    });

    const sections = this.createSections();
    const wrapper = this.createWrapper(sections);
    const bottom = this.createBottomButtons();

    container.append(wrapper, bottom);

    return container;
  };

  private createSections = () => {
    const sections = [];
    if (config.get('gallery.enabled')) {
      sections.push(this.getSectionGallery());
    }
    return sections;
  };

  private createWrapper = (sections) => {
    const wrapper = DOM.new('div', {
      class: 'wrapper',
    });

    wrapper.append(
      ...sections
        .map((item) => (item.settings > 0 ? item.section : null))
        .filter((item) => item !== null)
    );

    return wrapper;
  };

  private createBottomButtons = () => {
    const bottom = DOM.new('div', {
      class: 'bottom',
    });

    const applyButton = DOM.new('div', {
      class: 'apply ns',
      text: 'Apply',
    });

    const cancelButton = DOM.new('div', {
      class: 'cancel ns',
      text: 'Cancel',
    });

    [
      [
        applyButton,
        'settingsApplyClick',
        () => this.apply(document.querySelector('.settingsContainer'), this.client),
      ],
      [cancelButton, 'settingsCancelClick', () => this.close()],
    ].forEach(([element, id, callback]) => {
      bottom.append(element as Node);

      eventHooks.listen(
        element as HTMLElement,
        'click',
        id.toString(),
        callback as (...args: any) => void,
        {
          onAdd: this.removeOnUnbind,
        }
      );
    });

    return bottom;
  };

  private attachEventListeners = (container: HTMLElement) => {
    container.querySelectorAll('div.section > .option.interactable').forEach((option, index) => {
      if (option instanceof HTMLElement) {
        eventHooks.listen(
          option,
          'mouseup',
          `settingsMouseUp_${index}`,
          (e: MouseEvent) => {
            if (window.getSelection().toString()) {
              return;
            }

            const target = e.target as HTMLElement;
            if (target.tagName !== 'INPUT') {
              const checkbox = option.querySelector('input[type="checkbox"]') as HTMLInputElement;

              if (checkbox) {
                checkbox.checked = !checkbox.checked;
              }
            }
          },
          {
            onAdd: this.removeOnUnbind,
          }
        );
      }
    });
  };

  private makeDraggable = (element: HTMLElement) => {
    let pos1 = 0;
    let pos2 = 0;
    let pos3 = 0;
    let pos4 = 0;
    const header = element.querySelector('.wrapper > div:first-child') as HTMLElement;
    if (header) {
      header.style.cursor = 'move';
      header.onmousedown = dragMouseDown;
    } else {
      element.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e: MouseEvent) {
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }

    function elementDrag(e: MouseEvent) {
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      element.style.top = `${element.offsetTop - pos2}px`;
      element.style.left = `${element.offsetLeft - pos1}px`;
    }

    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  };
}
