/**
 * Checks an object for the existence of a nested path
 * @param obj - The object to check
 * @param path - The path to check, as a string or array of strings
 * @returns True if the path exists, false otherwise
 */
export const checkNestedPath = (
  obj: Record<string, any>,
  path: string | Array<string>
): boolean => {
  path = Array.isArray(path) ? path : path.split('.');

  for (let i = 0; i < path.length; i++) {
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, path[i])) {
      return false;
    }

    obj = obj[path[i]];
  }

  return true;
};

/**
 * Checks an object for a nested value
 * @param obj - The object to check
 * @param args - The path to check, as a series of strings
 * @returns True if the nested value exists, false otherwise
 */
export const checkNested = (obj: Record<string, any>, ...args: Array<string>): boolean => {
  for (let i = 0; i < args.length; i++) {
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, args[i])) {
      return false;
    }

    obj = obj[args[i]];
  }

  return true;
};

/**
 * Sets an object value using a path
 * @param obj - The object to modify
 * @param path - The path to set, as a string or array of strings
 * @param value - The value to set
 * @returns True if the value was set, false otherwise
 */
export const setNestedPath = (
  obj: Record<string, any>,
  path: string | Array<string>,
  value: any
): boolean => {
  path = Array.isArray(path) ? path : path.split('.');

  let wasSet = false;

  for (let i = 0; i < path.length; i++) {
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, path[i])) {
      continue;
    }

    if (i === path.length - 1) {
      obj[path[i]] = value;

      wasSet = true;
    }

    obj = obj[path[i]];
  }

  return wasSet;
};

/**
 * Retrieves an object value using a path
 * @param obj - The object to retrieve the value from
 * @param path - The path to retrieve, as a string or array of strings
 * @param fallback - The fallback value if the path does not exist
 * @returns The value at the path, or the fallback value
 */
export const getNestedPath = (
  obj: Record<string, any>,
  path: string | Array<string>,
  fallback: any
): any => {
  path = Array.isArray(path) ? path : path.split('.');

  for (let i = 0; i < path.length; i++) {
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, path[i])) {
      return fallback;
    }

    obj = obj[path[i]];
  }

  return obj;
};

/**
 * Retrieves an object value using a path
 * @param obj - The object to retrieve the value from
 * @param fallback - The fallback value if the path does not exist
 * @param args - The path to retrieve, as a series of strings
 * @returns The value at the path, or the fallback value
 */
export const getNested = (
  obj: Record<string, any>,
  fallback: any,
  ...args: Array<string>
): any => {
  for (let i = 0; i < args.length; i++) {
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, args[i])) {
      return fallback;
    }

    obj = obj[args[i]];
  }

  return obj;
};

/**
 * Applies a nested value to an object key
 * @param dest - The destination object
 * @param key - The key to set on the destination object
 * @param origin - The origin object to retrieve the value from
 * @param fallback - The fallback value if the nested value does not exist
 * @param nested - The path to the nested value, as a series of strings
 * @returns The value set on the destination object
 */
export const applyNested = (
  dest: Record<string, any>,
  key: string,
  origin: Record<string, any>,
  fallback: any,
  ...nested: Array<string>
): any => {
  if (dest) {
    const exists = checkNested(origin, ...nested);

    dest[key] = exists ? getNested(origin, fallback, ...nested) : fallback;

    return dest[key];
  }

  return null;
};

/**
 * Tests the existence of a path within an object
 * @param obj - The object to check
 * @param path - The path to check, as a string
 * @returns True if the path exists, false otherwise
 */
export const objHas = (obj: Record<string, any>, path: string): boolean => {
  let level: string = null;
  let rest: Array<string> = [];

  if (!path.includes('.')) {
    level = path;
  } else {
    rest = path.split('.');
    level = rest.shift();
  }

  if (obj === undefined) {
    return false;
  }

  if (rest.length === 0 && Object.prototype.hasOwnProperty.call(obj, level)) {
    return true;
  }

  return objHas(obj[level], rest.join('.'));
};
