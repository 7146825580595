/**
 * Debounce function for quick-firing events like window resize
 * @param f - The function to debounce
 * @param delay - The debounce delay in milliseconds (default is 100ms)
 * @returns A debounced function
 */
export const debounce = (f: (e: Event) => void, delay = 100): ((e: Event) => void) => {
  let timer: number | null = null;

  return (e: Event) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = window.setTimeout(() => f(e), delay);
  };
};
