/**
 * Gets the value of a table cell
 * @param row - The table row element
 * @param index - The index of the cell in the row
 * @returns The value of the cell
 */
const getCellValue = (row: HTMLElement, index: number): string | null => {
  const cell = row.querySelector(`td:nth-child(${index + 1})`);
  if (!cell) return null;

  const attribute = cell.getAttribute('data-raw');
  return attribute !== null ? attribute : cell.textContent;
};

/**
 * Compares cell values (table sort)
 * @param index - The index of the cell to compare
 * @returns A comparison function for sorting
 */
export const comparer = (index: number) => {
  return (a: HTMLElement, b: HTMLElement): number => {
    const valA = getCellValue(a, index) || '';
    const valB = getCellValue(b, index) || '';

    // Check if we're sorting dates (index 1)
    if (index === 1) {
      const dateA = new Date(valA);
      const dateB = new Date(valB);
      return dateB.getTime() - dateA.getTime(); // Sort in descending order
    }

    const numA = Number.parseFloat(valA);
    const numB = Number.parseFloat(valB);

    if (!Number.isNaN(numA) && Number.isFinite(numA) && !Number.isNaN(numB) && Number.isFinite(numB)) {
      return numA - numB;
    }

    return valA.localeCompare(valB);
  };
};
