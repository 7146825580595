/**
 * Converts a byte integer into a human-readable object
 * @param format - An array of size units (e.g., ["B", "KB", "MB", "GB"])
 * @param bytes - The size in bytes to convert (default is 0)
 * @returns An object with value and unit representing the human-readable size
 */
export const getReadableSize = (format: Array<string>, bytes = 0): { value: string, unit: string } => {
  if (bytes === 0) return { value: "0.00", unit: format[0] };

  let i = 0;

  while (bytes >= 1024 && i < format.length - 1) {
    bytes /= 1024;
    i++;
  }

  return {
    value: Math.max(bytes, 0.1).toFixed(i < 2 ? 0 : 2),
    unit: format[i]
  };
};
