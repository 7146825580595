interface HTMLDOMElement extends HTMLElement {
  domId?: number;
}

type TDomStructure = {
  cache?: {
    id: number;
  };
  new: (
    type: string,
    options?: {
      [key: string]: any;
    }
  ) => HTMLElement;
  wrap: (
    element: HTMLElement,
    type?: string,
    options?: {
      [key: string]: any;
    }
  ) => HTMLElement;
  style: {
    set: (
      element: HTMLElement,
      styling: {
        [key: string]: string;
      }
    ) => void;
  };
  attributes: {
    set: (
      element: HTMLElement,
      attributes: {
        [key: string]: string;
      }
    ) => void;
  };
  getIndex: (element: HTMLElement) => number;
};

/**
 * Manipulates the DOM
 */
export const DOM: TDomStructure = {
  cache: {
    id: 0,
  },
  /**
   * Creates a new DOM element
   * @param type - The type of the element to create
   * @param options - Optional attributes and properties for the element
   * @returns The newly created HTMLElement
   */
  new: (
    type: string,
    options: {
      [key: string]: any;
    } = {}
  ): HTMLElement => {
    const element: HTMLDOMElement = document.createElement(type);
    const attributes: Array<string> = Object.keys(options);

    element.domId = DOM.cache.id;

    attributes.forEach((attribute: string): void => {
      if (attribute.toLowerCase() === 'text') {
        element.textContent = options[attribute];
      } else {
        element.setAttribute(attribute, options[attribute]);
      }
    });

    DOM.cache.id++;

    return element;
  },
  /**
   * Wraps a DOM element
   * @param element - The element to wrap
   * @param type - The type of the wrapper element
   * @param options - Optional attributes and properties for the wrapper
   * @returns The wrapper element containing the original element
   */
  wrap: (
    element: HTMLElement,
    type = 'div',
    options: {
      [key: string]: any;
    } = {}
  ): HTMLElement => {
    const container: HTMLDOMElement = DOM.new(type, options);

    container.appendChild(element);

    return container;
  },
  /**
   * Element styling
   */
  style: {
    /**
     * Set CSS using objects
     * @param element - The element to style
     * @param styling - An object containing CSS properties and values
     */
    set: (element: HTMLElement, styling: { [key: string]: string }): void => {
      if (element) {
        const keys: Array<string> = Object.keys(styling);

        keys.forEach((key: string): void => {
          element.style[key] = styling[key];
        });
      }
    },
  },
  attributes: {
    /**
     * Set attributes on an element
     * @param element - The element to set attributes on
     * @param attributes - An object containing attribute names and values
     */
    set: (element: HTMLElement, attributes: { [key: string]: string }): void => {
      if (element) {
        const keys: Array<string> = Object.keys(attributes);

        keys.forEach((key: string): void => {
          element.setAttribute(key, attributes[key]);
        });
      }
    },
  },
  /**
   * Get the index of an element within its parent
   * @param element - The element to find the index of
   * @returns The index of the element within its parent's children
   */
  getIndex: (element: HTMLElement): number => {
    return Array.from(element.parentNode.children).indexOf(element);
  },
};
