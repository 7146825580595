/** Vendors */
import hoverPreview from './vendors/hover-preview/hover-preview';
/** Config */
import { config } from './config/config';
import data from './config/data';

// Import VidStack for web components
// We're importing these for the side-effect of registering the web components
// The actual component registration is done in initializeVidStack
import 'vidstack';
import 'vidstack/player';
import 'vidstack/player/ui';
/** Modules */
import { log } from './modules/logger';
import { eventHooks } from './modules/event-hooks';
/** Classes */
import optimizeClass from './classes/optimize';
/** Components */
import {
  componentGallery,
  componentSettings,
  componentFilter,
  componentBind,
  componentMain,
} from './components';

/** Helpers */
import {
  DOM,
  debounce,
  setVideoVolume,
  identifyExtension,
  stripUrl,
  getScrollbarWidth,
} from './helpers';

/** Types */
import {
  type ITableRowMI,
  type IPreviewAnchor,
  type TOnPreviewLoad,
  type TPreviewOptions,
  type TExtensionArray,
  IWindowGlobals,
  IDocumentGlobals,
} from './types';

// No direct imports of non-JS files
// SCSS is compiled separately with sass
// Template is copied separately

const debug = (message: string, ...args: any[]) => {
  if (config.get('debug')) {
    console.log(message, ...args);
  }
};

// The version handling is now done by the separate version-display.js script
// This removes duplicate version fetching/processing for better performance

document.addEventListener('DOMContentLoaded', () => {
  // Version is handled by version-display.js
  
  // Make sure VidStack is properly initialized
  // Initialize VidStack immediately rather than in a timeout
  // This ensures components are registered as early as possible
  try {
    // Call our global init function - it will properly handle the case where
    // elements are already registered, and provide better error handling
    if (typeof (window as any).initializeVidStack === 'function') {
      (window as any).initializeVidStack()
        .then((success: boolean) => {
          if (success) {
            debug('VidStack components initialized successfully');
          } else {
            console.warn('VidStack initialization returned false');
          }
        })
        .catch((err: Error) => {
          console.error('Error during VidStack initialization:', err);
        });
    } else {
      console.warn('VidStack initialization function not found');
    }
  } catch (error) {
    console.error('Error with VidStack initialization:', error);
  }

  const updateCursorPosition = (e: MouseEvent) => {
    const target = e.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    target.style.setProperty('--cursor-x', `${x}%`);
    target.style.setProperty('--cursor-y', `${y}%`);
  };

  document.querySelectorAll('.topBar, .bottom').forEach((element) => {
    element.addEventListener('mousemove', updateCursorPosition);
    element.addEventListener('mouseleave', () => {
      (element as HTMLElement).style.animationPlayState = 'running';
    });
    element.addEventListener('mouseenter', () => {
      (element as HTMLElement).style.animationPlayState = 'paused';
    });
    
    // Set initial position to center
    (element as HTMLElement).style.setProperty('--cursor-x', '50%');
    (element as HTMLElement).style.setProperty('--cursor-y', '50%');
  });

  debug('DOMContentLoaded event fired');
  try {
    // Initialize core functionality
    initializeCore();

    // Remove loading class when everything is ready
    window.addEventListener('load', () => {
      log('Main', 'Window load event fired');
      requestAnimationFrame(() => {
        document.body.classList.remove('js-loading');
        document.documentElement.classList.remove('no-fouc');
      });
    });
  } catch (error) {
    console.error('Error in DOMContentLoaded event handler:', error);
  }
});

function initializeCore() {
  debug('Initializing core...');

  try {
    // Disable media play
    try {
      navigator.mediaSession.setActionHandler('play', null);
    } catch (error) {
      debug('Error setting media session action handler:', error);
    }

    debug('Setting main component...');
    data.components.main = componentMain;
    debug('Main component set:', data.components.main);

    debug('Assigning components...');
    assignComponents();
    
    debug('Initializing settings...');
    initializeSettings();

    debug('Initializing page DOM...');
    initializePageDom();

    debug('Initializing performance mode...');
    initializePerformanceMode();

    debug('Initializing events...');
    initializeEvents();

    debug('Initializing previews...');
    initializePreviews();

    debug('Initiating listeners...');
    data.components.main.bind();

    debug('Setting scrollbar width...');
    document.documentElement.style.setProperty('--scrollbar-width', `${getScrollbarWidth()}px`);

    debug('Loading modification dates...');
    data.components.main.dates.load();

    debug('Resetting filter input...');
    resetFilterInput();

    debug('Creating menu...');
    createMenu();

    debug('Loading sorting indicators...');
    componentMain.sort.load();

    debug('Removing loading state...');
    document.body.removeAttribute('is-loading');

    debug('Initializing gallery events...');
    initializeGalleryEvents();

    debug('Initializing layout toggle...');
    initializeLayoutToggle();

    debug('Core initialization complete.');
    log('main', 'Config loaded =>', config.data);

    // Make necessary components available globally
    (window as any).data = data;
    (window as any).config = config;
  } catch (error) {
    console.error('Error during core initialization:', error);
  }
}

// Expose initialization functions globally
(window as any).reinitializeComponents = reinitializeComponents;
(window as any).initializeEvents = initializeEvents;
(window as any).initializePreviews = initializePreviews;
(window as any).initializeGalleryEvents = initializeGalleryEvents;

// Expose a function to initialize VidStack elements using the new Player API
(window as any).initializeVidStack = async function() {
  try {
    console.log('Initializing VidStack using Player API...');
    
    // First try to import the Player from the main package
    try {
      const vidstack = await import('vidstack');
      
      // Check if the Player object is available directly in the main module
      if (vidstack && vidstack.Player) {
        console.log('Found Player in main vidstack module');
        setupPlayerHelpers(vidstack.Player);
        return true;
      }
    } catch (mainImportErr) {
      console.warn('Could not import Player from main vidstack module:', mainImportErr);
    }
    
    // If that fails, try importing from player subdirectory
    try {
      const playerModule = await import('vidstack/player');
      
      if (playerModule && playerModule.Player) {
        console.log('Found Player in vidstack/player module');
        setupPlayerHelpers(playerModule.Player);
        return true;
      }
    } catch (playerImportErr) {
      console.warn('Could not import Player from vidstack/player module:', playerImportErr);
    }
    
    // As a fallback, try using the default export pattern
    try {
      const Player = (await import('vidstack')).default;
      if (Player && typeof Player.create === 'function') {
        console.log('Found Player as default export');
        setupPlayerHelpers(Player);
        return true;
      }
    } catch (defaultImportErr) {
      console.warn('Could not import Player as default export:', defaultImportErr);
    }
    
    // If we get here, we failed to find the Player object
    console.error('Failed to import VidStack Player API - falling back to HTML5 video');
    setupFallbackHelpers();
    return false;
  } catch (err) {
    console.error('Failed to initialize VidStack:', err);
    setupFallbackHelpers();
    return false;
  }
  
  // Helper function to set up the player creation and destruction APIs
  function setupPlayerHelpers(Player) {
    // Create a helper function for creating player instances
    (window as any).createVidstackPlayer = function(container, src, options = {}) {
      if (!container || !src) {
        console.error('Container element and source are required for VidStack player');
        return null;
      }
      
      try {
        // Use the official Player.create() API
        const player = Player.create({
          target: container,
          src: src,
          // Default options with overrides
          ...{
            controls: true,
            playsInline: true,
            loop: true,
          },
          ...options
        });
        
        console.log('VidStack player instance created successfully');
        return player;
      } catch (err) {
        console.error('Failed to create VidStack player:', err);
        return null;
      }
    };
    
    // Create a helper function for destroying player instances
    (window as any).destroyVidstackPlayer = function(player) {
      if (!player) return false;
      
      try {
        if (typeof player.destroy === 'function') {
          player.destroy();
          return true;
        }
        return false;
      } catch (err) {
        console.error('Error destroying VidStack player:', err);
        return false;
      }
    };
    
    console.log('VidStack Player API initialized successfully');
  }
  
  // Setup dummy helpers that just return null/false when VidStack fails to load
  function setupFallbackHelpers() {
    console.log('Setting up HTML5 video fallback helpers');
    
    // Only set these functions if they don't already exist
    if (typeof (window as any).createVidstackPlayer !== 'function') {
      // Dummy create function that always returns null
      (window as any).createVidstackPlayer = function() {
        console.warn('Using HTML5 video fallback - VidStack not available');
        return null;
      };
    }
    
    if (typeof (window as any).destroyVidstackPlayer !== 'function') {
      // Dummy destroy function that does nothing
      (window as any).destroyVidstackPlayer = function() {
        return false;
      };
    }
    
    // Log confirmation that fallback helpers are set up
    console.log('HTML5 video fallback helpers initialized');
  }
};

function reinitializeComponents(): void {
  debug('Reinitializing components...');

  // Reinitialize date formatting
  if (data.components.main?.dates) {
    data.components.main.dates.load();
  }

  // Reinitialize sorting
  if (data.components.main?.sort) {
    data.components.main.sort.load();
  }

  // Reset filter
  if (data.components.filter) {
    data.components.filter.apply('');
  }

  // Reinitialize optimization if enabled
  if (data.instances.optimize?.main?.enabled) {
    data.instances.optimize.main.refactor();
  }

  // Reinitialize events - critical for gallery functionality
  initializeEvents();

  // Reinitialize previews if enabled
  if (config.get('preview.enabled')) {
    initializePreviews();
  }

  // Reinitialize gallery events
  initializeGalleryEvents();

  // Reinitialize layout toggle
  initializeLayoutToggle();

  debug('Components reinitialized.');
}

function initializeLayoutToggle() {
  const toggleLayoutButton = document.getElementById('toggleLayout');
  if (toggleLayoutButton) {
    toggleLayoutButton.addEventListener('click', () => {
      document.body.classList.toggle('boxed-layout');
      localStorage.setItem('layout', document.body.classList.contains('boxed-layout') ? 'boxed' : 'full');
    });
  }

  // Set initial layout based on localStorage
  const savedLayout = localStorage.getItem('layout');
  if (savedLayout === 'boxed') {
    document.body.classList.add('boxed-layout');
  }
}

function initializeSettings() {
  // Add event listener for "Open Settings" button
  const settingsButton = document.querySelector('.menuContainer #settings');
  if (settingsButton) {
    settingsButton.addEventListener('click', (e) => {
      e.stopPropagation();
      data.components.settings.show();
    });
  }

  // Close settings when clicking outside
  document.addEventListener('click', (event) => {
    const settingsContainer = document.querySelector('.settingsContainer');
    if (
      settingsContainer &&
      !settingsContainer.contains(event.target as Node) &&
      event.target !== settingsButton
    ) {
      data.components.settings.close();
    }
  });

  // Add event listener for "Open Gallery" button
  const openGalleryButton = document.querySelector('.menuContainer #gallery');
  if (openGalleryButton) {
    openGalleryButton.addEventListener('click', () => {
      if (config.get('gallery.enabled') === true) {
        data.components.gallery.load(null);
        data.components.main.menu.toggle(false);
      }
    });
  }
}

function initializePageDom() {
  const selector = data.instances.selector;
  data.layer.main = {
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    scrolledY: window.scrollY,
    update: () => {
      const table = selector.use('TABLE_CONTAINER') as HTMLElement;
      data.layer.main.windowHeight = window.innerHeight;
      data.layer.main.windowWidth = window.innerWidth;
      data.layer.main.scrolledY = window.scrollY;
      data.layer.main.tableWidth = table.offsetWidth;
      return true;
    },
  };
  data.layer.main.update();
}

function initializePerformanceMode() {
  if (config.get('performance')) {
    const onRowChange = (rows: Array<ITableRowMI>): undefined | boolean => {
      let mediaIndex = 0;
      for (let index = 0; index < rows.length; index++) {
        if (rows[index].children[0].children[0].classList.contains('preview')) {
          rows[index]._mediaIndex = mediaIndex;
          mediaIndex++;
        }
      }
    };

    setTimeout(() => {
      requestAnimationFrame((): void => {
        data.instances.optimize.main = new optimizeClass({
          page: data.layer.main,
          table: data.instances.selector.use('TABLE') as HTMLElement,
          scope: [window, 'scrollY'],
          padding: 0,
          on: {
            rowChange: onRowChange,
          },
        });
      });
    }, 1);
  }
}

function initializeEvents() {
  console.log('Initializing events and setting up gallery click handlers at', new Date().toISOString());
  const selector = data.instances.selector;

  eventHooks.listen(
    document.querySelector('.menuContainer #settings') as HTMLElement,
    'click',
    'settingsClick',
    (e) => {
      data.components.main.menu.toggle(e.currentTarget);
    }
  );

  eventHooks.listen(selector.use('FILTER_INPUT') as HTMLElement, 'input', 'filterInput', (e) => {
    data.components.filter.apply(e.currentTarget.value);
  });

  eventHooks.listen(selector.use('FILTER_INPUT') as HTMLElement, 'focus', 'searchFocus', (e) => {
    const input = e.currentTarget as HTMLInputElement;
    if (input.value === input.placeholder) {
      input.value = '';
    }
    input.style.paddingLeft = '35px'; // Adjust padding for icon
    const searchIcon = selector.use('SEARCH_ICON');
    if (searchIcon instanceof HTMLElement) searchIcon.classList.add('focused');
  });

  eventHooks.listen(selector.use('FILTER_INPUT') as HTMLElement, 'blur', 'searchBlur', (e) => {
    const input = e.currentTarget as HTMLInputElement;
    if (input.value === '') {
      input.value = input.placeholder;
      input.style.paddingLeft = '15px'; // Reset padding
      const searchIcon = selector.use('SEARCH_ICON');
      if (searchIcon instanceof HTMLElement) searchIcon.classList.remove('focused');
    }
  });

  eventHooks.listen(
    selector.use('TABLE') as HTMLElement,
    'click',
    'sortClick',
    (event: MouseEvent) => {
      console.log('Table click event fired, target:', event.target);
      const eventTarget: HTMLElement = event.target as HTMLElement;

      if (eventTarget.tagName === 'SPAN' && eventTarget.hasAttribute('sortable')) {
        data.components.main.sortTableColumn(eventTarget);
      } else if (
        config.get('gallery.enabled') === true &&
        eventTarget.tagName === 'A' &&
        (eventTarget.className === 'preview' || eventTarget.getAttribute('data-is-preview') === 'preview')
      ) {
        console.log('Gallery preview link clicked:', {
          target: eventTarget,
          href: eventTarget.getAttribute('href'),
          className: eventTarget.className,
          galleryEnabled: config.get('gallery.enabled')
        });
        
        // Always prevent default browser behavior for preview links
        event.preventDefault();
        event.stopPropagation();

        // Get the file extension to determine if it's a media file
        const href = eventTarget.getAttribute('href');
        const extensions = config.get('extensions');
        const identified = identifyExtension(stripUrl(href), {
          image: extensions.image,
          video: extensions.video,
        });

        // Always calculate the proper index for gallery navigation
        let index = 0;

        if (data.instances.optimize.main.enabled) {
          const parent: ITableRowMI = eventTarget.closest('tr') as HTMLElement;
          if (parent._mediaIndex) index = parent._mediaIndex;
        } else {
          (selector.use('TABLE') as HTMLElement)
            .querySelectorAll('tr.file:not(.filtered) a.preview')
            .forEach((element: HTMLAnchorElement, i: number) => {
              if (eventTarget === element) index = i;
            });
        }

        // Debug information
        if (config.get('debug')) {
          console.log('Gallery item clicked:', {
            href,
            index,
            identified,
            target: eventTarget
          });
        }

        // Load the gallery with the correct index - wrapped in try/catch for error protection
        try {
          data.components.gallery.load(index);
        } catch (err) {
          console.error('Error loading gallery:', err);
          alert('Gallery failed to load. Please try again or contact support if the issue persists.');
        }
      }
    }
  );

  // Handle window resize with error protection
  try {
    eventHooks.listen(
      window,
      'resize',
      'windowResize',
      debounce((): void => {
        try {
          log('event', 'windowResize (main)', 'Resized.');
          
          // Use a safe check for Modernizr
          if (typeof Modernizr !== 'undefined' && typeof Modernizr.mq === 'function') {
            config.set('mobile', Modernizr.mq('(max-width: 768px)'));
          } else {
            // Fallback if Modernizr is not available
            config.set('mobile', window.matchMedia('(max-width: 768px)').matches);
          }

      if (data.instances.gallery) {
        data.instances.gallery.options.mobile = config.get('mobile');
        data.instances.gallery.update.listWidth();
      }

      data.layer.main.update();

      if (data.instances.optimize?.main?.enabled) {
        data.instances.optimize.main.attemptRefresh();
      }
        } catch (err) {
          console.error('Error in window resize handler:', err);
        }
      })
    );
  } catch (err) {
    console.error('Error setting up window resize event:', err);
  }
}

function initializePreviews() {
  if (config.get('mobile') === false && config.get('preview.enabled') === true) {
    const previews = {};
    let resume = null;
    let timerReadyState = null;

    const onLoaded = (event: TOnPreviewLoad) => {
      log('preview', 'Preview loaded =>', event);

      if (data.preview.data?.element) {
        data.preview.data.element.remove();
      }

      if (!data.preview.isLoadable) return null;

      const [element, type, src] = [event.element, event.type, event.src];

      data.preview.data = event;

      /* Clear timer */
      clearInterval(timerReadyState);

      if (element && type === 'VIDEO') {
        /* If a resume is set, then set currentTime */
        if (resume && resume.src === src) {
          (element as HTMLVideoElement).currentTime = resume.timestamp;
        } else {
          resume = null;
        }

        /* Set stored preview volume */
        setVideoVolume(element as HTMLVideoElement, data.preview.volume / 100, false);

        /* Check for valid readystate (4, 3, 2) before we show the video */
        timerReadyState = setInterval(() => {
          if ((element as HTMLVideoElement).readyState > 1) {
            /* Show video */
            DOM.style.set(element, {
              visibility: 'visible',
            });

            /* Clear timer */
            clearInterval(timerReadyState);
          }
        }, 25);
      } else {
        /* Not a video, clear resume */
        resume = null;
      }

      /* Store timestamp if exists */
      if (Object.prototype.hasOwnProperty.call(event, 'timestamp')) {
        const timestamp = event.timestamp;

        resume = {
          src,
          timestamp,
        };
      }

      /* If video is audible, enable scrollLock */
      if (event.loaded && event.audible) {
        data.scrollLock = true;
      } else {
        data.scrollLock = false;
      }
    };

    const createPreview = (element: IPreviewAnchor) => {
      const src: string = element.getAttribute('href');
      const extensions: TExtensionArray = config.get('extensions');

      const identified = identifyExtension(stripUrl(src), {
        image: extensions.image,
        video: extensions.video,
      });

      if (identified) {
        const [extension, type] = identified;

        const options: TPreviewOptions = {};

        /* Delay prior to showing preview */
        options.delay = config.get('preview.hoverDelay');

        /* Loading cursor */
        options.cursor = config.get('preview.cursorIndicator');

        /* Encoding */
        options.encodeAll = config.get('encodeAll');

        /* Events */
        options.on = {
          onLoaded: onLoaded,
        };

        /* Force set extension data */
        options.force = {
          extension,
          type,
        };

        /* Create preview */
        previews[element.itemIndex] = hoverPreview(element, options);
      }
    };

    const previewable = document.querySelectorAll(
      'body > div.tableContainer > table > tbody > tr.file > td > a.preview'
    );

    previewable.forEach((preview: IPreviewAnchor, index) => {
      preview.itemIndex = index;
      if (index === 0) createPreview(preview);
    });

    eventHooks.listen(
      data.instances.selector.use('TABLE') as HTMLElement,
      'mouseover',
      'previewMouseEnter',
      (e) => {
        if (e.target.tagName === 'A' && e.target.className === 'preview') {
          const index = e.target.itemIndex;
          if (!Object.prototype.hasOwnProperty.call(previews, index)) {
            createPreview(e.target);
          }
        }
      }
    );
  }
}

function assignComponents() {
  debug('Assigning components...');
  try {
    // Create settings with error handling
    try {
      data.components.settings = new componentSettings();
    } catch (err) {
      console.error('Error creating settings component:', err);
      // Fallback to object creation
      data.components.settings = Object.create(componentSettings.prototype);
      componentSettings.call(data.components.settings);
    }
    
    // Create gallery with error handling
    try {
      console.log('Creating gallery component with', typeof componentGallery);
      data.components.gallery = new componentGallery();
    } catch (err) {
      console.error('Error creating gallery component:', err);
      // Fallback to direct object assignment with prototype methods
      const galleryObj = Object.create(componentGallery.prototype);
      componentGallery.call(galleryObj);
      data.components.gallery = galleryObj;
    }
    
    // Create bind component
    try {
      data.components.bind = new componentBind();
    } catch (err) {
      console.error('Error creating bind component:', err);
      data.components.bind = Object.create(componentBind.prototype);
      componentBind.call(data.components.bind);
    }
    
    // Assign filter directly since it's not a constructor
    data.components.filter = componentFilter;
    
    debug('Components assigned:', data.components);
  } catch (err) {
    console.error('Fatal error assigning components:', err);
  }

  if (data.components.main) {
    data.components.main.bind = data.components.bind.load;
    data.components.main.unbind = data.components.bind.unbind;
  } else {
    console.error('data.components.main is not defined');
  }
}

function resetFilterInput() {
  const filterInput = document.body.querySelector(
    ':scope > .filterContainer > input[type="text"]'
  ) as HTMLInputElement;
  if (filterInput) {
    filterInput.value = filterInput.placeholder;
  }
}

function createMenu() {
  debug('Creating menu...');
  try {
    if (typeof data.components.main.menu.create === 'function') {
      const menu = data.components.main.menu.create();
      const topBar = document.querySelector('body > div.topBar') as HTMLDivElement;

      if (menu && topBar) {
        const height = topBar.offsetHeight;
        DOM.style.set(menu, {
          top: `${height}px`,
          visibility: 'unset',
          display: 'none',
        });
      } else {
        console.warn('Menu or topBar element not found');
      }
    } else {
      console.error('menu.create is not a function');
    }
  } catch (error) {
    console.error('Error creating menu:', error);
  }
}

function initializeGalleryEvents() {
  // Subscribe to gallery lifecycle events for proper binding/unbinding
  eventHooks.subscribe('galleryBound', 'mainUnbind', () => {
    try {
      if (data.components.main && typeof data.components.main.unbind === 'function') {
        data.components.main.unbind();
      }
    } catch (error) {
      console.error('Error unbinding main component:', error);
    }
  });

  eventHooks.subscribe('galleryUnbound', 'mainBind', () => {
    try {
      if (data.components.main && typeof data.components.main.bind === 'function') {
        data.components.main.bind();
      }
    } catch (error) {
      console.error('Error binding main component:', error);
    }
  });
  
  // Additional cleanup when gallery closes
  eventHooks.subscribe('galleryUnbound', 'cleanupMedia', () => {
    try {
      console.log('Gallery closed - performing additional cleanup');
      
      // Find and remove any stray VidStack elements that might have been left behind
      setTimeout(() => {
        try {
          const vidstackElements = document.querySelectorAll('media-player, .vidstack-player-container');
          if (vidstackElements.length > 0) {
            console.log(`Found ${vidstackElements.length} stray VidStack elements, removing...`);
            vidstackElements.forEach(element => {
              if (element.parentNode) {
                element.parentNode.removeChild(element);
              }
            });
          }
        } catch (err) {
          console.warn('Error in additional media cleanup:', err);
        }
      }, 100);
    } catch (error) {
      console.error('Error in gallery cleanup handler:', error);
    }
  });
}
