/**
 * Gets the width of the scrollbar
 * @returns The width of the scrollbar in pixels
 */
export const getScrollbarWidth = (): number => {
  if (!(document.body.scrollHeight > window.innerHeight)) {
    return 0;
  }

  const outer: HTMLDivElement = document.createElement('div');

  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';

  document.body.appendChild(outer);

  const inner = document.createElement('div');

  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};
