/** Vendors */
import cookies from 'js-cookie';
import Swipe, { type EventData } from 'vanilla-swipe';
/** Config */
import data from '../../config/data';
import { user } from '../../config/config';
/** Modules */
import { log } from '../../modules/logger';
import { eventHooks } from '../../modules/event-hooks';
/** Classes */
import optimizeClass from './../optimize';
/** Helpers */
import { DOM, debounce, shortenString } from '../../helpers';
// Gallery stylesheet is compiled with main.scss

/** Constants */
import { Keys, CookieKey } from '../../constant';

/** Types */
import type {
  TGalleryDefaults,
  TPageObject,
  TGalleryDataActive,
  TGalleryTableItem,
  IGalleryOptions,
  TUserStorage,
  TPayloadgalleryItemChanged,
  THTMLElement,
  HTMLElementExtend,
} from '../../types';

export default class galleryClass {
  private isVisible: null | boolean;
  private container: THTMLElement;
  private table: THTMLElement;
  private list: THTMLElement;
  private defaults: object;
  private items: Array<TGalleryTableItem>;
  private optimize: optimizeClass;
  private page: TPageObject;
  public data: TGalleryDataActive;
  public options: IGalleryOptions;
  private currentVideo: HTMLVideoElement | null = null;
  private isLoadingVideo = false;
  private videoInstanceCount = 0;
  private currentLoadingSource: string | null = null;
  private videoLoadTimeout: number | null = null;

  constructor(items: Array<TGalleryTableItem>, options: object = {}) {
    console.log('Gallery constructor called', new Date().getTime());
    /* Get default values */
    const defaults = this.setDefaults();

    /* Override any default values passed as an option */
    Object.keys(defaults).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(options, key)) {
        options[key] = defaults[key];
      }
    });

    this.isVisible = null;

    /* Set options */
    this.options = options;

    /* Initiate */
    this.init(items);

    return this;
  }

  private setDefaults = (): object => {
    const data: TGalleryDefaults = {};

    /* Valid extensions */
    data.extensions = {
      image: ['jpg', 'jpeg', 'gif', 'png', 'ico', 'svg', 'bmp', 'webp'],
      video: ['mp4', 'webm', 'ogg', 'ogv', 'mov', 'mkv', 'ts', 'm4v', '3gp', 'avi', 'wmv', 'flv'],
    };

    /* Item list */
    data.list = {
      show: true,
      reverse: false,
    };

    /* Video */
    data.video = {
      video: null,
    };

    /* Performance mode */
    data.performance = false;

    /* Video autoplay */
    data.autoplay = true;

    /* Video volume */
    data.volume = 0;

    /* Verbose */
    data.console = true;

    /* Sharpen images */
    data.sharpen = true;

    /* Mobile mode */
    data.mobile = false;

    /* Fit content to fill space */
    data.fitContent = false;

    /* Encode all characters */
    data.encodeAll = false;

    /* Forced scroll break */
    data.scrollInterval = 35;

    /* Start index */
    data.start = 0;

    /* List alignment */
    data.listAlignment = 0;

    /* Set class variable */
    this.defaults = data;

    return this.defaults;
  };

  /**
   * Initiates the class
   *
   * Set values, options and call initiating functions
   */
  private init = (items: Array<TGalleryTableItem>) => {
    /* Create data object */
    this.data = {};

    /* Busy state */
    this.data.busy = false;

    /* Store bound listeners */
    this.data.boundEvents = {};

    /* Scrollbreak state */
    this.data.scrollbreak = false;

    /* Apply prevent default to these keys */
    this.data.keyPrevent = [
      Keys.pageUp,
      Keys.pageDown,
      Keys.arrowLeft,
      Keys.arrowUp,
      Keys.arrowRight,
      Keys.arrowDown,
    ];

    this.data.selected = {
      src: null,
      ext: null,
      index: null,
      type: null,
    };

    this.container = document.body.querySelector(':scope > div.rootGallery');
    this.items = this.options.filter ? this.filterItems(items) : items;

    if (this.items.length === 0) {
      return false;
    }

    if (!this.exists()) {
      this.initiate((): void => {
        this.bind();
      });
    } else {
      this.show(true);
    }

    const start =
      this.options.start > this.items.length - 1 ? this.items.length - 1 : this.options.start;

    this.navigate(start);

    /* Enable optimizer if enabled */
    if (this.options.performance) {
      this.useOptimizer(this.table);
    }

    /* Hide list if option is set or on mobile */
    if (!this.options.list.show || this.options.mobile) {
      this.list.style.display = 'none';
    }
  };

  /**
   * Preloads an image
   */
  private loadImage = (
    src: string
  ): Promise<{
    src: string;
    img: HTMLImageElement;
    cancelled: boolean;
    dimensions: {
      height: number;
      width: number;
    };
  }> => {
    eventHooks.unsubscribe('galleryItemChanged', 'loadImage');

    return new Promise((resolve, reject) => {
      let img: HTMLImageElement = document.createElement('img');

      const onError = (): void => {
        reject(new Error(`failed to load image URL: ${src}`));
      };

      const onLoad = (): void => {
        const dimensions = {
          width: img.naturalWidth,
          height: img.naturalHeight,
        };

        resolve({ src, img, dimensions, cancelled: false });
      };

      img.src = src;

      /* Add listeners */
      img.addEventListener('error', onError, true);
      img.addEventListener('load', onLoad, true);

      eventHooks.subscribe(
        'galleryItemChanged',
        'loadImage',
        (event: TPayloadgalleryItemChanged) => {
          /* If current source has changed to something else, cancel load */
          if (event.source !== src) {
            /* Remove listeners */
            img.removeEventListener('error', onError, true);
            img.removeEventListener('load', onLoad, true);

            /* Clear image object */
            img.src = '';
            img = null;

            /* "self destruct" on trigger */
            eventHooks.unsubscribe('galleryItemChanged', 'loadImage');
          }

          resolve({ src, img: null, dimensions: null, cancelled: true });
        }
      );
    });
  };

  /**
   * Checks if an element has a scrollbar
   */
  private elementHasScrollbar = (element: HTMLElement): boolean => {
    let height = element.getBoundingClientRect().height;
    const style = window.getComputedStyle(element);

    height = ['top', 'bottom']
      .map((side) => {
        return Number.parseInt(style[`margin-${side}`], 10);
      })
      .reduce((total, side) => {
        return total + side;
      }, height);

    return height > window.innerHeight;
  };

  /**
   * Encodes a URL
   */
  private encodeUrl = (input: string): string => {
    let encoded = !this.options.encodeAll ? encodeURI(input) : input;

    if (this.options.encodeAll) {
      encoded = encoded.replace('#', '%23').replace('?', '%3F');
    }

    encoded = encoded.replace('+', '%2B');

    return encoded;
  };

  /**
   * Gets the extension from a filename
   */
  private getExtension = (filename: string): string => {
    return filename.split('.').pop().toLowerCase();
  };

  /**
   * Checks if the filename is an image
   */
  private isImage = (filename: string, extension: string | null = null): boolean => {
    return this.options.extensions.image.includes(
      extension ? extension : this.getExtension(filename)
    );
  };

  /**
   * Checks if the filename is a video
   */
  private isVideo = (filename: string, extension: string | null = null): boolean => {
    return this.options.extensions.video.includes(
      extension ? extension : this.getExtension(filename)
    );
  };

  /**
   * Filters an array of items to make sure it only contains videos and images
   */
  private filterItems = (items: Array<TGalleryTableItem>) => {
    return items.filter((item: { name: string }): boolean => {
      return this.isImage(item.name) || this.isVideo(item.name);
    });
  };

  /**
   * Gets the width of the scrollbar
   */
  private getScrollbarWidth = (): number => {
    if (!this.elementHasScrollbar(document.body)) {
      return 0;
    }

    const outer: HTMLDivElement = document.createElement('div');

    DOM.style.set(outer, {
      visibility: 'hidden',
      overflow: 'scroll',
      msOverflowStyle: 'scrollbar',
    });

    document.body.appendChild(outer);

    const inner = document.createElement('div');

    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  };

  /**
   * Limits the body (hides overflow etc.)
   *
   * This allows the gallery to operate without a scrollbar in the background
   */
  limitBody = (bool = true): void => {
    const body: HTMLElement = document.body;
    const root: HTMLElement = document.documentElement;
    const scrollpadding: number = this.getScrollbarWidth();

    if (bool === true) {
      root.setAttribute('gallery-is-visible', '');
      this.isVisible = true;

      this.data.body = {
        'max-height': body.style.maxHeight,
        overflow: body.style.overflow,
        'padding-right': root.style.paddingRight,
      };

      if (scrollpadding > 0) {
        root.style.paddingRight = `${scrollpadding}px`;
      }

      body.style.maxHeight = '100vh';
      body.style.overflow = 'hidden';
    } else {
      root.removeAttribute('gallery-is-visible');
      this.isVisible = false;

      if (this.data.body) {
        body.style.maxHeight = this.data.body['max-height'] || '';
        body.style.overflow = this.data.body.overflow || '';
        root.style.paddingRight = this.data.body['padding-right'] || '';
      }

      // Ensure all gallery-related styles are removed
      body.style.removeProperty('max-height');
      body.style.removeProperty('overflow');
      root.style.removeProperty('padding-right');
    }
  };

  /**
   * Checks for an active gallery DOM element
   */
  private exists = (): boolean => {
    this.container = document.body.querySelector(':scope > div.rootGallery');

    return !!this.container;
  };

  /**
   * Shows or hides the gallery
   */
  public show = (
    bool = true,
    index: null | number = null,
    items: Array<TGalleryTableItem> = null
  ): void => {
    if (items) {
      log('gallery', 'itemsUpdate', true);

      this.data.selected.index = null;
      this.items = this.options.filter ? this.filterItems(items) : items;
      this.populateTable(this.items);
    }

    if (bool === true) {
      this.bind().style.display = 'block';

      if (index !== this.data.selected.index) {
        const elements: NodeList = this.container.querySelectorAll(
          ':scope > div.galleryContent > div.media > div.wrapper img, \
					:scope > div.galleryContent > div.media > div.wrapper video'
        );

        elements.forEach((element: HTMLElement) => {
          element.style.display = 'none';
        });

        this.navigate(index);

        if (items && this.options.performance) {
          this.useOptimizer(this.table);
        }
      }
    } else {
      this.unbind();
      this.container.style.display = 'none';
      this.limitBody(false);
      this.resetGalleryState();
      this.stopAndCleanupVideo();

      // Ensure scrolling is re-enabled
      document.body.style.overflow = '';
      document.documentElement.style.paddingRight = '';

      // Force a repaint to ensure styles are applied
      document.body.offsetHeight;
    }

    this.limitBody(bool);

    if (bool === true) {
      const video: HTMLVideoElement = this.container.querySelector(
        ':scope > div.galleryContent > div.media > div.wrapper video'
      );

      if (video && video.style.display !== 'none') {
        let currentTime: number = video.currentTime;
        let sourceMatch = false;

        if (
          this.options.continue.video &&
          Object.prototype.hasOwnProperty.call(this.options.continue.video, 'src')
        ) {
          sourceMatch =
            video.querySelector('source').getAttribute('src') === this.options.continue.video.src;
        }

        if (this.options.continue.video && sourceMatch) {
          currentTime = this.options.continue.video.time;
          this.options.continue.video = null;
        }

        video.currentTime = currentTime;
        video.muted = false;
        video[this.options.autoplay ? 'play' : 'pause']();

        this.video.setVolume(video, this.video.getVolume());
      }
    }

    /* Optimization refreshing */
    if (bool && this.options.performance && this.optimize && this.list && this.table) {
      const selectedItem: HTMLElement = this.table.querySelector('tr.selected');

      let selectedItemTop: number | boolean = Number.parseInt(selectedItem.style.top.replace(/\D+/g, ''));

      if (Number.isInteger(selectedItemTop) && !(selectedItemTop >= 0)) {
        selectedItemTop = false;
      }

      if (selectedItemTop) {
        if (
          !(
            this.list.scrollTop <= selectedItemTop &&
            selectedItemTop <= this.list.scrollTop + this.list.offsetHeight
          )
        ) {
          this.list.scrollTo(0, selectedItemTop);
        }
      }

      this.optimize.attemptRefresh();
    }
  };

  private stopAndCleanupVideo = (): void => {
    if (!this.currentVideo) {
      console.log('No video to clean up');
      return; // Nothing to clean up
    }
    
    console.log('Stopping and cleaning up video', new Date().getTime());
    
    // Check if this is a VidStack player
    if (this.currentVideo.dataset?.isVidstack === 'true') {
      console.log('Cleaning up VidStack player');
      
      try {
        // First, collect all references safely before we start removing things
        const videoElement = this.currentVideo;
        const videoParent = videoElement.parentNode;
        const playerInstance = videoElement._vidstackPlayer;
        const container = videoElement._vidstackContainer;
        
        // Stop any active media playback first using the player instance
        if (playerInstance) {
          try {
            // Always try to pause first to stop any sound
            if (typeof playerInstance.pause === 'function') {
              playerInstance.pause();
            }
            
            // Mute audio to prevent unexpected sounds during cleanup
            try {
              if ('muted' in playerInstance) {
                playerInstance.muted = true;
              }
            } catch (e) {
              // Ignore muting errors
            }
            
            // Use proper destroy method from player instance
            if (typeof playerInstance.destroy === 'function') {
              console.log('Using player.destroy() method for cleanup');
              playerInstance.destroy();
            } else if (typeof playerInstance.dispose === 'function') {
              // Some versions might use dispose instead
              console.log('Using player.dispose() method for cleanup');
              playerInstance.dispose();
            } else if (typeof (window as any).destroyVidstackPlayer === 'function') {
              // Legacy global helper (fallback)
              console.log('Using global destroyVidstackPlayer method');
              (window as any).destroyVidstackPlayer(playerInstance);
            }
          } catch (playerErr) {
            console.warn('Error cleaning up player instance:', playerErr);
          }
        }
        
        // Clean up stored event listeners if any
        if (videoElement._eventListeners && Array.isArray(videoElement._eventListeners)) {
          try {
            console.log(`Removing ${videoElement._eventListeners.length} tracked event listeners`);
            videoElement._eventListeners.forEach(({ element, event, handler }) => {
              try {
                if (element && typeof element.removeEventListener === 'function') {
                  element.removeEventListener(event, handler);
                }
              } catch (listenerErr) {
                console.warn(`Error removing listener for ${event}:`, listenerErr);
              }
            });
            videoElement._eventListeners = [];
          } catch (eventsErr) {
            console.warn('Error cleaning up event listeners:', eventsErr);
          }
        }
        
        // Remove the container element from the DOM if still present
        if (container && container.parentNode) {
          try {
            container.parentNode.removeChild(container);
          } catch (containerErr) {
            console.warn('Error removing player container from DOM:', containerErr);
          }
        }
        
        // Remove the compatibility video element
        if (videoElement && videoParent) {
          try {
            videoParent.removeChild(videoElement);
          } catch (videoErr) {
            console.warn('Error removing video element from DOM:', videoErr);
          }
        }
        
        // Clear all references 
        if (videoElement) {
          try {
            // Clear any circular references to help garbage collection
            videoElement._vidstackPlayer = null;
            videoElement._vidstackContainer = null;
            videoElement._eventListeners = null;
          } catch (e) {
            // Ignore reference clearing errors
          }
        }
      } catch (error) {
        console.error('Error during VidStack player cleanup:', error);
      }
    } else {
      // Standard HTML5 video cleanup with better error handling
      try {
        // 1. Stop playback
        try { 
          this.currentVideo.pause(); 
        } catch (e) {
          console.warn('Error pausing video:', e);
        }
        
        // 2. Reset position and mute
        try {
          this.currentVideo.currentTime = 0;
          this.currentVideo.muted = true;
          this.currentVideo.volume = 0;
        } catch (e) {
          console.warn('Error resetting video state:', e);
        }
        
        // 3. Remove source and force unload
        try {
          this.currentVideo.removeAttribute('src');
          const sources = this.currentVideo.querySelectorAll('source');
          if (sources && sources.length > 0) {
            Array.from(sources).forEach(source => {
              source.removeAttribute('src');
            });
          }
          
          // Force unload
          if (typeof this.currentVideo.load === 'function') {
            try {
              this.currentVideo.load();
            } catch (loadErr) {
              console.warn('Error during video unload:', loadErr);
            }
          }
        } catch (e) {
          console.warn('Error removing video sources:', e);
        }
        
        // 4. Remove event listeners
        this.cleanupVideoListeners(this.currentVideo);
        
        // 5. Remove from DOM
        try {
          if (this.currentVideo.parentNode) {
            this.currentVideo.parentNode.removeChild(this.currentVideo);
          }
        } catch (e) {
          console.warn('Error removing video from DOM:', e);
        }
      } catch (videoErr) {
        console.error('Error cleaning up HTML5 video:', videoErr);
      }
    }
    
    // Clear all state variables
    this.currentVideo = null;
    this.isLoadingVideo = false;
    this.currentLoadingSource = null;
    this.videoInstanceCount = 0; // Reset the instance count
    
    // Make sure there are no lingering video elements in the wrapper
    // This is a final safety check to catch any elements we might have missed
    try {
      const wrapper = document.querySelector('.rootGallery .galleryContent .media .wrapper');
      if (wrapper) {
        // Search for all potential video-related elements
        const videoSelectors = [
          'video', 
          'media-player', 
          '.vidstack-player-container',
          '[data-vidstack-player]', 
          '[data-isVidstack="true"]'
        ];
        
        const videoElements = wrapper.querySelectorAll(videoSelectors.join(', '));
        if (videoElements.length > 0) {
          console.log(`Found ${videoElements.length} leftover video elements to clean up`);
          videoElements.forEach(element => {
            try {
              if (element.parentNode) {
                element.parentNode.removeChild(element);
              }
            } catch (e) {
              console.warn('Error removing leftover media element:', e);
            }
          });
        }
      }
    } catch (e) {
      console.warn('Error in final media element cleanup:', e);
    }
    
    // Force a DOM repaint to ensure all elements are properly removed
    try {
      if (document.body) {
        document.body.clientHeight;
      }
    } catch (e) {
      // Ignore
    }
    
    console.log('Video cleanup complete', new Date().getTime());
  };

  /**
   * Sets the busy state (while loading images/videos)
   */
  private busy = (bool?: boolean): boolean => {
    if (bool === true || bool === false) {
      this.data.busy = bool;

      const loader: HTMLElement = this.container.querySelector(
        ':scope > div.galleryContent > div.media > div.spinner'
      );

      if (bool) {
        DOM.style.set(loader, {
          opacity: '1',
        });
      } else {
        loader.style.opacity = '0';
      }
    }

    return this.data.busy;
  };

  /**
   * Enables the optimizer (performance mode) on the gallery list
   */
  private useOptimizer = (table: HTMLElement): optimizeClass => {
    /* Removes any previous optimize instances */
    if (this.optimize) {
      this.optimize = undefined;

      eventHooks.unlisten(this.list, 'scroll', 'galleryTableScroll');

      DOM.style.set(this.table, {
        height: 'auto',
      });
    }

    /* Creates a page object for the optimizer */
    const page: TPageObject = {
      update: () => {
        page.windowHeight = window.innerHeight;
        page.windowWidth = window.innerWidth;
        page.scrolledY = window.scrollY;

        return true;
      },
      scope: table,
    };

    data.layer.gallery = page;

    /* Update function (called on scroll, resize etc.) */
    page.update = () => {
      page.windowHeight = window.innerHeight;
      page.windowWidth = window.innerWidth;
      page.scrolledY = this.list.scrollTop;

      return true;
    };

    /** Initiating update call */
    page.update();

    this.page = page;

    /* Initialize optimize class */
    this.optimize = new optimizeClass({
      page: page,
      table: table,
      scope: [this.list, 'scrollTop'],
    });

    /** Remove any previous listeners */
    eventHooks.unlisten(window, 'resize', 'windowGalleryResize');

    /** Listen to window resizing */
    eventHooks.listen(
      window,
      'resize',
      'windowGalleryResize',
      debounce((): void => {
        if (this.options.performance && this.optimize.enabled) {
          log('gallery', 'windowResize (gallery)', 'Resized.');
          page.update();
        }
      })
    );

    let scrollEndTimer: null | number = null;

    /** Listen to list scrolling */
    eventHooks.listen(this.list, 'scroll', 'galleryTableScroll', (): void => {
      if (this.options.performance && this.optimize.enabled) {
        /* Get scrolled position */
        const scrolled = this.list.scrollTop;

        /* Trigger optimization refresh if 175 px has been scrolled */
        if (Math.abs(scrolled - this.page.scrolledY) > 175) {
          this.optimize.attemptRefresh();
        }

        clearTimeout(scrollEndTimer);

        scrollEndTimer = window.setTimeout(() => {
          this.optimize.attemptRefresh();
        }, 150);
      }
    });

    return this.optimize;
  };

  /**
   * Populates the gallery table
   */
  private populateTable = (items: Array<TGalleryTableItem>, table?: HTMLElement): HTMLElement => {
    log('gallery', 'Populating gallery list ..');

    table = table || this.container.querySelector('div.galleryContent > div.list > table');

    const buffer: Array<string> = [];

    for (let i = 0; i <= items.length - 1; i++) {
      buffer[i] = `<tr title="${items[i].name}"><td>${items[i].name}</td></tr>`;
    }

    /* Set directly all at once instead of appending (faster .. ? probably?) */
    table.innerHTML = buffer.join('');

    this.list = this.container.querySelector('div.galleryContent > div.list');
    this.table = table;

    return table;
  };

  /**
   * Updating functions
   */
  public update = {
    /* Updates the list width */
    listWidth: (wrapper?: HTMLElement): void => {
      wrapper =
        wrapper ||
        this.container.querySelector(':scope > div.galleryContent > div.media > div.wrapper');

      const list: HTMLElement = this.data.list
        ? this.data.list
        : this.container.querySelector(':scope > div.galleryContent > div.list');

      const width =
        this.options.mobile || !list || list.style.display === 'none' ? 0 : list.offsetWidth;

      wrapper.style.setProperty('--width-list', `${width}px`);
    },
  };

  /**
   * Apply functions
   */
  private apply = {
    cache: {
      info: null,
    },
    timers: {
      dimensions: null,
    },
    /* Sets an item dimension notification on navigate change */
    itemDimensions: (index: number) => {
      const item: TGalleryTableItem = this.items[index];
      let media: HTMLElement = this.container.querySelector('div.media > div.item-info-static');

      if (
        Object.prototype.hasOwnProperty.call(item, 'dimensions') &&
        item.dimensions.height > 0 &&
        item.dimensions.width > 0
      ) {
        if (!media) {
          media = DOM.new('div', {
            class: 'item-info-static',
          });

          this.container.querySelector('div.media').appendChild(media);
        }

        media.style.opacity = '1';
        media.style.display = 'inline-block';

        media.textContent = `${item.dimensions.width} x ${item.dimensions.height} (${item.size})`;
      } else if (media) {
        media.style.display = 'none';
      }

      clearTimeout(this.apply.timers.dimensions);

      this.apply.timers.dimensions = setTimeout(() => {
        if (media) {
          media.style.opacity = '0';
        }
      }, 3e3);
    },
    /* Displays item information in the top bar */
    itemInfo: (
      update: boolean,
      item: TGalleryTableItem | null = null,
      index: number | null = null,
      max: number | null = null
    ): boolean => {
      if (update) {
        if (Array.isArray(this.apply.cache.info)) {
          [item, index, max] = this.apply.cache.info;
        } else if (item === null || index === null || max === null) {
          return false;
        }
      } else {
        this.apply.cache.info = [item, index, max];

        return false;
      }

      const download: HTMLElement = this.container.querySelector(
        '.galleryBar > .galleryBarRight > a.download'
      );

      const left: HTMLElement = this.container.querySelector(
        ':scope > div.galleryBar > div.galleryBarLeft'
      );

      const name: string = this.options.mobile ? shortenString(item.name, 30) : item.name;
      const url: string = this.encodeUrl(item.url);

      DOM.attributes.set(download, {
        filename: item.name,
        href: url,
        title: `Download: ${item.name}`,
      });

      const buffer: Array<string> = [
        `<span>${index + 1} of ${max}</span>`,
        `<a target="_blank" href="${url}">${name}</a>`,
      ];

      if (Object.prototype.hasOwnProperty.call(item, 'size') && !this.options.mobile) {
        buffer.push(`<span>${item.size}</span>`);
      }

      left.innerHTML = buffer.join('');

      return true;
    },
  };

  /* Checks if a list item is scrolled into view */
  private isScrolledIntoView = (container: HTMLElement, element: HTMLElement): boolean => {
    const parent = {
      scrolled: container.scrollTop,
      height: container.offsetHeight,
    };

    const child = {
      offset: element.offsetTop,
      height: (element.children[0] as HTMLElement).offsetHeight,
    };

    log('gallery', 'isScrolledIntoView', parent, child);

    return (
      child.offset >= parent.scrolled &&
      child.offset + child.height <= parent.scrolled + parent.height
    );
  };

  /**
   * Calculates the navigational index
   */
  private calculateIndex = (current: number, change: number, max: number): number => {
    let adjusted = current + change;

    if (adjusted > max) {
      adjusted = adjusted - max - 1;
    }

    if (adjusted < 0) {
      adjusted = max - (Math.abs(adjusted) - 1);
    }

    if (adjusted < 0 || adjusted > max) {
      return this.calculateIndex(current, max - adjusted, max);
    }

    return adjusted;
  };

  /**
   * Video functions
   */
  private video = {
    /* Creates a video element */
    create: (extension: string): [HTMLVideoElement, HTMLSourceElement] => {
      console.log('Creating video element', new Date().getTime());
      this.videoInstanceCount++;
      console.log(`Creating video instance #${this.videoInstanceCount}`);

      const video: HTMLVideoElement = DOM.new('video', {
        controls: '',
        preload: 'auto',
        loop: '',
      }) as HTMLVideoElement;

      // Browser detection isn't needed anymore as we use a consistent approach for all browsers
      
      // Map each extension to its correct MIME type
      const mimeTypeMap = {
        // Common web formats
        'mp4': 'mp4',
        'webm': 'webm',
        'ogg': 'ogg',
        'ogv': 'ogg',
        // Apple formats
        'mov': 'mp4',
        'm4v': 'mp4',
        // Other formats
        'mkv': 'x-matroska',
        // Transport stream - use mp4 mime type for better compatibility
        'ts': 'mp4', 
        '3gp': '3gpp',
        'avi': 'x-msvideo',
        'wmv': 'x-ms-wmv',
        'flv': 'x-flv'
      };
      
      // Safely get MIME type or use a safe default
      const mimeType = mimeTypeMap[extension] || 
                      (this.options.extensions.video.includes(extension) ? extension : 'mp4');
      
      let source: HTMLSourceElement;
      
      // Enhanced handling for all video types, with specific attention to .ts files
      if (extension === 'ts') {
        console.log('Using enhanced handling for .ts files');
        
        // Primary source - try as MP4 (best compatibility)
        source = DOM.new('source', {
          type: 'video/mp4',
          src: '',
        }) as HTMLSourceElement;
        
        // Secondary source - try with standard MIME type as fallback
        const source2: HTMLSourceElement = DOM.new('source', {
          type: 'video/mp2t', 
          src: '',
        }) as HTMLSourceElement;
        
        // Try as x-mpegts (sometimes recognized)
        const source3: HTMLSourceElement = DOM.new('source', {
          type: 'video/x-mpegts',
          src: '',
        }) as HTMLSourceElement;
        
        // Last option - no type specified, let browser detect
        const source4: HTMLSourceElement = DOM.new('source', {
          src: '',
        }) as HTMLSourceElement;
        
        video.append(source, source2, source3, source4);
      } else {
        // Standard handling for other video formats, but always add a fallback
        source = DOM.new('source', {
          type: `video/${mimeType}`,
          src: '',
        }) as HTMLSourceElement;
        
        // Fallback source without type specified - helps browser-native formats
        const fallbackSource = DOM.new('source', {
          src: '',
        }) as HTMLSourceElement;
        
        video.append(source, fallbackSource);
      }

      this.video.setVolume(video, this.video.getVolume());

      video.addEventListener('canplay', () => console.log('Canplay event triggered'));
      video.addEventListener('playing', () => console.log('Playing event triggered'));

      return [video, source];
    },
    /* Volume getter */
    getVolume: (): number => {
      let volume = Number.parseFloat(this.options.volume.toString());

      volume = Number.isNaN(volume) || volume < 0 || volume > 1 ? 0 : volume;

      return volume;
    },
    /* Volume setter */
    setVolume: (video: HTMLVideoElement, i: number): number => {
      if (i > 0) {
        video.volume = i >= 1 ? 1.0 : i;
      } else {
        video.muted = true;
      }

      return i;
    },
    /* Video seeker */
    seek: (i: number): undefined | boolean => {
      const video: HTMLVideoElement = this.container.querySelector(
        ':scope > div.galleryContent > div.media > div.wrapper video'
      );

      if (video) {
        const current = Math.round(video.currentTime);
        const duration = Math.round(video.duration);

        if (i > 0) {
          if (current + i > duration) {
            return true;
          }
            video.currentTime = current + i;
        } else if (i < 0) {
          if (current + i < 0) {
            return true;
          }
            video.currentTime = current + i;
        }

        return false;
      }
    },
  };

  private ensureSingleVideoInstance(wrapper: HTMLElement, src: string): HTMLVideoElement {
    console.log('Ensuring single video instance', new Date().getTime());
    if (this.currentVideo) {
      if (this.currentVideo.dataset.srcId !== src) {
        console.log('Removing existing video');
        this.stopAndCleanupVideo();
      } else {
        console.log('Reusing existing video instance');
        return this.currentVideo;
      }
    }
    console.log('Creating new video instance');
    
    try {
      // Attempt to create VidStack player
      console.log('Attempting to create VidStack player');
      return this.createVidStackPlayer(wrapper, src);
    } catch (vidstackError) {
      // If VidStack fails, fall back to simple HTML5 video
      console.error('VidStack player creation failed, falling back to HTML5 video:', vidstackError);
      return this.createFallbackVideo(wrapper, src);
    }
  }
  
  // Create a simple HTML5 video element as fallback
  private createFallbackVideo(wrapper: HTMLElement, src: string): HTMLVideoElement {
    console.log('Creating HTML5 fallback video');
    
    // Clean up any existing video elements first
    this.stopAndCleanupVideo();
    
    // Create a simple video element
    const video = document.createElement('video') as HTMLVideoElement;
    video.controls = true;
    video.playsInline = true;
    video.muted = this.options.volume === 0;
    video.autoplay = this.options.autoplay;
    video.loop = true;
    video.src = src;
    video.dataset.srcId = src;
    
    // Ensure proper styling
    video.style.display = 'block';
    video.style.width = '100%';
    video.style.height = '100%';
    video.style.maxHeight = '100vh';
    video.style.objectFit = 'contain';
    
    // Add event listeners for debugging
    video.addEventListener('canplay', () => console.log('HTML5 video can play'));
    video.addEventListener('playing', () => console.log('HTML5 video is playing'));
    video.addEventListener('error', (e) => console.error('HTML5 video error:', e));
    
    // Append to wrapper
    wrapper.appendChild(video);
    
    // Store as current video
    this.currentVideo = video;
    
    return video;
  }
  
  private async createVidStackPlayer(wrapper: HTMLElement, src: string): Promise<HTMLVideoElement> {
    console.log('Creating VidStack player using modern API', new Date().getTime());
    
    try {
      // Clean up any existing video elements first
      this.stopAndCleanupVideo();
      
      // Create a container for the player
      const containerId = `vidstack-container-${Date.now()}-${Math.floor(Math.random() * 10000)}`;
      const playerContainer = document.createElement('div');
      playerContainer.className = 'vidstack-player-container';
      playerContainer.id = containerId;
      playerContainer.style.width = '100%';
      playerContainer.style.height = '100%';
      playerContainer.style.display = 'flex';
      
      // Add container to the DOM first
      wrapper.appendChild(playerContainer);
      
      // Try to dynamically import VidStack
      let VidstackPlayer, VidstackPlayerLayout;
      
      try {
        // Attempt to use modern import
        const vidstack = await import('vidstack/global/player');
        VidstackPlayer = vidstack.VidstackPlayer;
        VidstackPlayerLayout = vidstack.VidstackPlayerLayout;
        
        console.log('Successfully imported VidStack player modules');
      } catch (importError) {
        console.warn('Failed to import VidStack modules:', importError);
        
        // Try fallback to global window variables (for direct script includes)
        if (typeof (window as any).VidstackPlayer === 'function') {
          VidstackPlayer = (window as any).VidstackPlayer;
          VidstackPlayerLayout = (window as any).VidstackPlayerLayout;
          console.log('Using globally available VidStack player');
        } else {
          console.error('VidStack player not available, falling back to HTML5 video');
          playerContainer.parentNode?.removeChild(playerContainer);
          return this.createFallbackVideo(wrapper, src);
        }
      }
      
      // Use the modern VidStack API to create a player
      let player;
      try {
        player = await VidstackPlayer.create({
          target: playerContainer,
          src: this.encodeUrl(src),
          title: 'Media Player',
          controls: true,
          playsInline: true,
          loop: true,
          autoplay: this.options.autoplay,
          muted: this.options.volume === 0,
          volume: this.options.volume,
          // Use default layout if available
          layout: VidstackPlayerLayout ? new VidstackPlayerLayout() : undefined
        });
        
        console.log('VidStack player created successfully');
      } catch (playerError) {
        console.error('Failed to create VidStack player:', playerError);
        playerContainer.parentNode?.removeChild(playerContainer);
        return this.createFallbackVideo(wrapper, src);
      }
      
      // Create a compatibility bridge element that maintains the HTMLVideoElement interface
      // but delegates operations to the VidStack player
      const compatVideo = document.createElement('video') as HTMLVideoElement;
      compatVideo.style.display = 'none';
      compatVideo.dataset.srcId = src;
      compatVideo.dataset.isVidstack = 'true';
      compatVideo.dataset.vidstackId = containerId;
      
      // Store references to the player and container
      compatVideo._vidstackPlayer = player;
      compatVideo._vidstackContainer = playerContainer;
      
      // Set up a proper event listeners collection for cleanup
      compatVideo._eventListeners = [];
      
      // Helper function to add and track event listeners
      const trackListener = (element, event, handler) => {
        element.addEventListener(event, handler);
        compatVideo._eventListeners.push({ element, event, handler });
      };
      
      // Implement HTMLVideoElement interface methods
      compatVideo.play = function(): Promise<void> {
        return new Promise((resolve) => {
          try {
            if (!this._vidstackPlayer) {
              console.warn('VidStack player not available for play()');
              resolve();
              return;
            }
            
            this._vidstackPlayer.play()
              .then(() => {
                console.log('VidStack play successful');
                resolve();
              })
              .catch(error => {
                console.warn('VidStack play failed, will try muted:', error);
                
                // Try muted if autoplay was blocked
                if (error?.name === 'NotAllowedError') {
                  this._vidstackPlayer.muted = true;
                  this._vidstackPlayer.play().catch(e => {
                    console.error('Even muted play failed:', e);
                  });
                }
                
                // Always resolve to avoid hanging UI
                resolve();
              });
          } catch (error) {
            console.error('Error in play() method:', error);
            resolve();
          }
        });
      };
      
      compatVideo.pause = function(): void {
        try {
          if (this._vidstackPlayer) {
            this._vidstackPlayer.pause();
          }
        } catch (error) {
          console.warn('Error in pause() method:', error);
        }
      };
      
      // Implement key property getters/setters using proper proxying
      Object.defineProperties(compatVideo, {
        volume: {
          get: function() {
            return this._vidstackPlayer?.volume ?? 1;
          },
          set: function(value) {
            if (this._vidstackPlayer) {
              this._vidstackPlayer.volume = Number(value) || 0;
            }
          }
        },
        muted: {
          get: function() {
            return this._vidstackPlayer?.muted ?? false;
          },
          set: function(value) {
            if (this._vidstackPlayer) {
              this._vidstackPlayer.muted = !!value;
            }
          }
        },
        currentTime: {
          get: function() {
            return this._vidstackPlayer?.currentTime ?? 0;
          },
          set: function(value) {
            if (this._vidstackPlayer) {
              this._vidstackPlayer.currentTime = Number(value) || 0;
            }
          }
        },
        duration: {
          get: function() {
            return this._vidstackPlayer?.duration ?? 0;
          }
        }
      });
      
      // Set up event listeners for player state changes
      if (player) {
        // Store key player state in bridge element for gallery code
        try {
          // Listen for provider setup to get video dimensions
          player.addEventListener('provider-setup', (event) => {
            try {
              console.log('VidStack provider setup complete', event);
              
              // Access provider for media dimensions
              const provider = event.detail;
              
              if (provider?.type === 'video' && provider.video) {
                // Wait for metadata to load to get dimensions
                provider.video.addEventListener('loadedmetadata', () => {
                  const mediaWidth = provider.video.videoWidth;
                  const mediaHeight = provider.video.videoHeight;
                  
                  if (mediaWidth && mediaHeight) {
                    console.log(`Video dimensions from provider: ${mediaWidth}x${mediaHeight}`);
                    
                    // Store dimensions in gallery items
                    const index = this.data.selected.index;
                    if (index !== null && index >= 0 && this.items[index]) {
                      this.items[index].dimensions = {
                        width: mediaWidth,
                        height: mediaHeight
                      };
                      
                      // Update UI
                      this.apply.itemDimensions(index);
                    }
                  }
                });
              }
            } catch (setupError) {
              console.warn('Error handling provider setup event:', setupError);
            }
          });
          
          // Subscribe to state changes
          player.subscribe(({ volume, muted }) => {
            try {
              if (typeof volume === 'number' || typeof muted === 'boolean') {
                this.options.volume = muted ? 0 : volume;
                eventHooks.trigger('galleryVolumeChange', this.options.volume.toString());
              }
            } catch (stateError) {
              console.warn('Error handling state subscription:', stateError);
            }
          });
          
          // Listen for player events and propagate to our compatibility element
          const eventMap = {
            'play': 'play',
            'pause': 'pause',
            'time-update': 'timeupdate',
            'volume-change': 'volumechange',
            'loaded-metadata': 'loadedmetadata',
            'loaded-data': 'loadeddata',
            'can-play': 'canplay',
            'ended': 'ended',
            'waiting': 'waiting',
            'error': 'error'
          };
          
          // Set up bidirectional event propagation
          Object.entries(eventMap).forEach(([vidstackEvent, htmlEvent]) => {
            const handler = (e) => {
              // Create and dispatch equivalent HTML5 video event
              const newEvent = new Event(htmlEvent);
              compatVideo.dispatchEvent(newEvent);
            };
            
            // Add to player and track for cleanup
            player.addEventListener(vidstackEvent, handler);
            compatVideo._eventListeners.push({ 
              element: player, 
              event: vidstackEvent, 
              handler 
            });
          });
        } catch (eventError) {
          console.warn('Error setting up player event listeners:', eventError);
        }
      }
      
      // Add compatibility video to DOM (hidden)
      wrapper.appendChild(compatVideo);
      
      // Store reference to the video element
      this.currentVideo = compatVideo;
      return compatVideo;
    } catch (error) {
      console.error('Failed to create VidStack player, falling back to HTML5 video:', error);
      return this.createFallbackVideo(wrapper, src);
    }
  }

  private cleanupVideoListeners(video: HTMLVideoElement) {
    if (!video) return;
    
    console.log('Cleaning up video listeners', new Date().getTime());
    
    // Common event types to clean up
    const commonEvents = [
      'canplay', 'canplaythrough', 'playing', 'pause', 'play',
      'timeupdate', 'seeking', 'seeked', 'volumechange', 'ended',
      'loadedmetadata', 'loadeddata', 'waiting', 'stalled', 'error'
    ];
    
    // Clean up event hooks first
    eventHooks.unlisten(video, commonEvents, 'awaitGalleryVideo');
    
    // Check for stored event listeners (for VidStack videos)
    if (video._eventListeners && Array.isArray(video._eventListeners)) {
      try {
        // Remove each stored listener
        video._eventListeners.forEach(listener => {
          try {
            if (listener && listener.element && listener.event && listener.handler) {
              listener.element.removeEventListener(listener.event, listener.handler);
            }
          } catch (err) {
            console.warn(`Failed to remove listener for ${listener?.event}:`, err);
          }
        });
        // Clear the listeners array
        video._eventListeners = [];
      } catch (err) {
        console.warn('Error cleaning up stored event listeners:', err);
      }
    }
    
    // For handling any direct DOM listeners, clone and replace technique
    try {
      if (video.parentNode) {
        const clone = video.cloneNode(false); // shallow clone without children
        if (video.parentNode) {
          // First clone and remove all event listeners
          video.parentNode.replaceChild(clone, video);
        }
      }
    } catch (cloneErr) {
      console.warn('Failed to clone element for listener removal:', cloneErr);
    }
  }

  private cancelCurrentVideoLoad() {
    if (this.videoLoadTimeout) {
      clearTimeout(this.videoLoadTimeout);
      this.videoLoadTimeout = null;
    }
    this.stopAndCleanupVideo();
    this.isLoadingVideo = false;
    this.currentLoadingSource = null;
  }


  /**
   * Shows an item (called on show, navigate etc.)
   */
   private showItem = (
     type: number,
     element: HTMLVideoElement | HTMLImageElement,
     src: string,
     init: boolean,
     index: number,
     data: {
       img?: {
         height: number;
         width: number;
       };
     } = null
   ): void => {
     console.log('showItem called', { type, src, init, index }, new Date().getTime());

     // Check if the gallery is being closed or not visible
     // We use document.body.contains(this.container) to check if the container is still in the DOM
     if (!this.isVisible || (this.container && !document.body.contains(this.container))) {
       console.log('Gallery is not visible or being closed, skipping item load');
       return;
     }
     
     // Quick sanity check for container existence
     if (!this.container) {
       console.error('Gallery container does not exist');
       return;
     }

     if (this.isLoadingVideo && this.currentLoadingSource === src) {
       console.log('Video load already in progress for this source, waiting');
       return;
     }

     if (this.isLoadingVideo) {
       console.log('Cancelling previous video load');
       this.cancelCurrentVideoLoad();
     }

     const wrapper: HTMLElement = this.container.querySelector(
       ':scope > div.galleryContent > div.media > div.wrapper'
     );

     let video: HTMLVideoElement;
     let source: HTMLSourceElement = null;
     let hasEvented = false;

     /* Hides the opposite media element */
     const hideOther = (): void => {
       const opposite: HTMLElement = wrapper.querySelector(type === 0 ? 'video' : 'img');

       if (opposite && type === 1) {
         (opposite.closest('.cover') as HTMLElement).style.display = 'none';
       }

       if (opposite) {
         opposite.style.display = 'none';
       }
     };

     const applyChange = (onChange?: () => void) => {
       const elements: NodeList = this.container.querySelectorAll(
         ':scope > \
 				div.galleryContent > div.media > div.wrapper > div:not(.cover)'
       );

       elements.forEach((element: HTMLElement) => element.remove());

       this.apply.itemInfo(true);
       this.data.selected.type = type;

       wrapper.style.display = '';

       if (onChange) {
         onChange();
       }

       this.busy(false);
     };

     const display = (): void => {
       /* Image type */
       if (type === 0) {
         // ... (keep the existing image handling code)
       } else if (type === 1) { /* Video type */
         this.isLoadingVideo = true;
         this.currentLoadingSource = src;
         console.log('Loading video', src);

         video = this.ensureSingleVideoInstance(wrapper, src);
         
         // Make sure video was created successfully
         if (!video) {
           console.error('Failed to create video instance');
           return;
         }
         
         // Find the source element, or create one if missing
         source = video.querySelector('source');
         if (!source) {
           source = document.createElement('source');
           video.appendChild(source);
         }

         /* Set video source */
         const currentSrc = source.getAttribute('src');
         if (currentSrc !== src) {
           source.setAttribute('src', src);
           video.dataset.srcId = src;
           if (typeof video.load === 'function') {
             video.load(); // Force the video to load the new source
           }
         }

        /** Triggered on video error */
        const error = (event: Event) => {
          // Check if we're dealing with a .ts file
          const isTS = this.data.selected.ext === 'ts';
          
          console.error(`Failed to load video source${isTS ? ' (.ts file)' : ''}.`, event);
          
          // If this is a .ts file, we might have alternative sources to try
          // Don't immediately show an error for .ts files
          if (!isTS) {
            this.busy(false);
            this.stopAndCleanupVideo();
            this.isLoadingVideo = false;
            this.currentLoadingSource = null;

            // Display an error message to the user
            const errorElement = DOM.new('div', {
              class: 'video-error',
              text: 'Error: Video could not be loaded.'
            });
            wrapper.appendChild(errorElement);
          } else {
            // For .ts files, just log the error but let the alternative sources try
            console.log('Continuing with alternative sources for .ts file');
          }
        };

        // Only set the onerror handler for the video element, not for individual sources
        // This allows other sources in the source chain to be tried without triggering errors
        video.onerror = error;

        // Add a timeout to handle cases where the video fails to load without triggering an error
        this.videoLoadTimeout = window.setTimeout(() => {
          if (!hasEvented) {
            error(new Event('timeout'));
          }
        }, 30000); // 30 seconds timeout

        /* Add video volume change listener */
        eventHooks.listen(video, 'volumechange', 'galleryVideoVolumeChange', (): void => {
          this.options.volume = video.muted ? 0 : Number.parseFloat(video.volume.toFixed(2));
          eventHooks.trigger('galleryVolumeChange', this.options.volume.toString());
        });

        /* Events that indicate that the video is "ready" */
        const videoReadyEvents: Array<string> = ['loadedmetadata', 'canplay'];

        /* Add video load listener */
        eventHooks.listen(
          video,
          videoReadyEvents,
          'awaitGalleryVideo',
          (): boolean | undefined => {
            console.log('Video ready event triggered', event.type, new Date().getTime());

            if (hasEvented || video.dataset.srcId !== this.data.selected.src) {
              return false;
            }

            if (this.videoLoadTimeout) {
              clearTimeout(this.videoLoadTimeout);
              this.videoLoadTimeout = null;
            }

            /* Video dimensions */
            const height: number = video.videoHeight;
            const width: number = video.videoWidth;

            this.items[index].dimensions = {
              height: height,
              width: width,
            };

            /* Apply dimensions */
            this.apply.itemDimensions(index);

            applyChange((): void => {
              if (this.options.fitContent) {
                this.update.listWidth(wrapper);

                DOM.style.set(video, {
                  width: 'auto',
                  height: `calc(calc(100vw - var(--width-list)) / ${(width / height).toFixed(4)})`,
                });
              }

              // Set volume with proper error handling
              try {
                if (this.options.volume > 0) {
                  video.volume = this.options.volume;
                  video.muted = false;
                } else {
                  video.muted = true;
                }
              } catch (volumeErr) {
                console.warn('Error setting video volume:', volumeErr);
              }

              /* Plays the video if the gallery is visible, otherwise pauses it */
              if (this.isVisible && this.options.autoplay) {
                console.log('Attempting to play video now that it is ready');
                
                try {
                  // First make sure the video is visible
                  video.style.display = 'block';
                  
                  // Ensure it has proper styles
                  video.style.width = '100%';
                  video.style.height = '100%';
                  video.style.maxHeight = '100vh';
                  video.style.objectFit = 'contain';
                  
                  // Try to play it
                  const playPromise = video.play();
                  if (playPromise && typeof playPromise.then === 'function') {
                    playPromise.catch(error => {
                      console.error('Error playing video:', error);
                      
                      // If autoplay is blocked, try again with muted
                      if (error && error.name === 'NotAllowedError') {
                        console.log('Autoplay blocked - trying muted playback');
                        video.muted = true;
                        
                        // Try playing again
                        video.play().catch(mutedErr => {
                          console.error('Even muted play failed:', mutedErr);
                        });
                      }
                    });
                  }
                } catch (playErr) {
                  console.error('Exception playing video:', playErr);
                }
              } else if (!this.isVisible) {
                try {
                  video.pause();
                } catch (pauseErr) {
                  console.warn('Error pausing video:', pauseErr);
                }
              }

              /* Hide any image elements from the cover */
              hideOther();

              /* Show video with proper display style */
              video.style.display = 'block'; // Use block instead of inline-block for better layout

              /* If the gallery was hidden while loading, pause video and hide loader. */
              if (this.container.style.display === 'none') {
                (
                  this.container.querySelector(
                    'div.galleryContent .media div.spinner'
                  ) as HTMLElement
                ).style.opacity = '0';

                video.pause();
              }

              hasEvented = true;
              this.currentVideo = video;
              this.isLoadingVideo = false;
              this.currentLoadingSource = null;
            });
          },
          {
            destroy: true,
          }
        );

        if (this.options.continue.video && src === this.options.continue.video.src) {
          video.currentTime = this.options.continue.video.time;
          this.options.continue.video = null;
        }
      }

      this.data.selected.index = index;
    };

    display();

  };

  /**
   * Navigates the gallery
   */
  private navigate = (index: number | null, step: number = null): boolean | undefined => {
    log('gallery', 'busyState', this.busy());

    /* Set maximum navigation index */
    const max = this.items.length - 1;

    /* Index defaulting */
    if (index === null) {
      index = this.data.selected.index || 0;
    }

    /* Step defaulting */
    if (step !== null) {
      index = this.calculateIndex(index, step, max);
    }

    if (this.data.selected.index === index) {
      return false;
    }

    let init = null;
    let item = null;

    const contentContainer: HTMLElement = this.container.querySelector(
      ':scope > div.galleryContent'
    );

    /* Select video and image elements */
    let image: HTMLImageElement = contentContainer.querySelector(
        ':scope > div.media > div.wrapper img'
      );
    let video: HTMLVideoElement = contentContainer.querySelector(
        ':scope > div.media > div.wrapper video'
      );

    /* Select list, table and table items */
    const list: HTMLElement = contentContainer.querySelector(':scope > div.list');
    const table: HTMLElement = list.querySelector('table');
    const element: HTMLElementExtend = table.querySelector(`tr:nth-child(${index + 1})`);

    item = this.items[index];

    const encodedItemSource = this.encodeUrl(item.url);

    this.data.selected.src = encodedItemSource;
    this.data.selected.ext = this.getExtension(item.name);

    /* Remove previously selected */
    if (table.querySelector('tr.selected')) {
      table.querySelector('tr.selected').classList.remove('selected');
    }

    /* Select element */
    element.classList.add('selected');

    /* Set item information */
    this.apply.itemInfo(!!(!image && !video ), item, index, max + 1);

    let hasScrolled = false;

    const useScrollOptimize: boolean =
      this.options.performance && this.optimize && this.optimize.enabled;

    if (useScrollOptimize && element.classList.contains('hid-row') && element._offsetTop >= 0) {
      const scrollPosition = element._offsetTop - list.offsetHeight / 2;
      /* Scroll to a hidden row as a result of optimization */
      list.scrollTo(0, scrollPosition >= 0 ? scrollPosition : 0);

      /* Set variable to indicate that we've scrolled here instead */
      hasScrolled = true;
    }

    /* Use default `scrollto` if item is out of view */
    if (!hasScrolled && !this.isScrolledIntoView(list, element)) {
      list.scrollTo(0, element.offsetTop);
    }

    /* Trigger gallery item change event */
    eventHooks.trigger('galleryItemChanged', {
      source: encodedItemSource,
      index: index,
    });

    /* If selected item is an image */
    if (this.isImage(null, this.data.selected.ext)) {
      /* Set busy state */
      this.busy(true);

      init = !image;

      if (video) {
        /* Pause any existing videos */
        video.pause();
      }

      /* If initial navigate, create image element */
      if (init === true) {
        const cover: HTMLElement = DOM.new('div', {
          class: 'cover',
          style: 'display: none',
        });

        const wrapper = this.container.querySelector(
          ':scope > div.galleryContent > div.media > div.wrapper'
        );

        image = DOM.new('img') as HTMLImageElement;

        wrapper.prepend(cover);
        cover.append(image);

        /* Listener for mouse enter on image cover */
        cover.addEventListener('mouseenter', (e: Event) => {});
      }

      /* Await image loading */
      this.loadImage(encodedItemSource)
        .then(({ src, dimensions, cancelled }) => {
          if (dimensions && !cancelled) {
            const [w, h] = [dimensions.width, dimensions.height];

            if (this.data.selected.src === src) {
              this.showItem(0, image, src, init, index, {
                img: {
                  width: w,
                  height: h,
                },
              });
            }
          }
        })
        .catch((error: unknown) => {
          /* Image could not be loaded */

          console.error(error);

          this.busy(false);
          this.data.selected.index = index;

          this.container
            .querySelectorAll(
              ':scope > div.galleryContent > div.media > div.wrapper img, \
					:scope > div.galleryContent > div.media > div.wrapper video'
            )
            .forEach((element: HTMLElement) => {
              element.style.display = 'none';
            });

          if (
            this.container.querySelector(
              ':scope > div.galleryContent > div.media > div.wrapper > div:not(.cover)'
            )
          ) {
            this.container
              .querySelector(
                ':scope > div.galleryContent > div.media > div.wrapper > div:not(.cover)'
              )
              .remove();
          }

          const imageError: HTMLElement = DOM.new('div', {
            class: 'error',
          });

          imageError.innerHTML = 'Error: Image could not be displayed.';

          this.container.querySelector('.media .wrapper').append(imageError);
        });

      return true;
    }

    /* If selected item is a video */
    if (this.isVideo(null, this.data.selected.ext)) {
      /* Set busy state */
      this.busy(true);

      init = !video;

      if (init) {
        /* Create video if initial navigate */
        video = this.video.create(this.data.selected.ext)[0];

        this.container
          .querySelector(':scope > div.galleryContent > div.media > div.wrapper')
          .append(video);
      }

      this.showItem(1, video, encodedItemSource, init, index);

      return true;
    }
  };

  /**
   * Handles keypresses
   */
  private handleKey = (key: string, callback: (prevent: boolean) => void) => {
    log('gallery', 'handleKey', key);

    if (key === Keys.escape) {
      /* Close gallery on `escape` */
      this.show(false);
    } else if (key === Keys.arrowDown || key === Keys.pageDown || key === Keys.arrowRight) {
      if (key === Keys.arrowRight && this.data.selected.type === 1) {
        /* Seek (+) video on `arrowRight` (video elements) */
        if (this.video.seek(5)) this.navigate(null, 1);
      } else {
        /* Next gallery item on `arrowRight` (image elements) */
        this.navigate(null, 1);
      }
    } else if (key === Keys.arrowUp || key === Keys.pageUp || key === Keys.arrowLeft) {
      if (key === Keys.arrowLeft && this.data.selected.type === 1) {
        /* Seek (-) video on `arrowLeft` (video elements) */
        if (this.video.seek(-5)) this.navigate(null, -1);
      } else {
        /* Previous gallery item on `arrowLeft` (image elements) */
        this.navigate(null, -1);
      }
    } else if (key === Keys.l) {
      /* Toggle list on `l` */
      this.toggleList();
    }

    callback(this.data.keyPrevent.includes(key));
  };

  /**
   * Prepares a listener to be removed on gallery unbind
   */
  private removeOnUnbind = (
    selector: HTMLElement,
    events: Array<string> | string,
    id: string
  ): void => {
    this.data.boundEvents[id] = {
      selector,
      events,
    };
  };

  /**
   * Unbinds gallery listeners (called on gallery hide)
   */
  private unbind = (): void => {
    Object.keys(this.data.boundEvents).forEach((eventId: string) => {
      const {
        selector,
        events,
      }: {
        selector: HTMLElement;
        events: Array<string> | string;
      } = this.data.boundEvents[eventId];

      if (selector && events) {
        eventHooks.unlisten(selector, events, eventId);
      }
    });

    this.data.boundEvents = {};

    eventHooks.trigger('galleryUnbound');
  };

  private resetGalleryState = (): void => {
    // Reset any gallery-specific styles or classes
    const galleryContent = this.container.querySelector('.galleryContent') as HTMLElement;
    if (galleryContent) {
      galleryContent.style.height = '';
    }

    // Reset list styles
    if (this.list) {
      this.list.style.width = '';
      this.list.style.display = '';
    }

    // Reset any other gallery-specific states here
  };

  /**
   * Scrollbreak
   */
  private scrollBreak = (): void => {
    this.data.scrollbreak = false;
  };

  /**
   * Toggles the visibility of the list of items
   */
  private toggleList = (element: HTMLElement = null): boolean => {
    const list: HTMLElement = this.container.querySelector(
      ':scope > div.galleryContent > div.list'
    );
    const visible: boolean = list.style.display !== 'none';
    const client: TUserStorage = user.get();

    client.gallery.listState = !visible ? 1 : 0;

    user.set(client);

    if (!element) {
      element = document.body.querySelector(
        'div.rootGallery > div.galleryBar .galleryBarRight span[data-action="toggle"]'
      );
    }

    element.innerHTML = `List<span class="inheritParentAction">${visible ? '+' : '-'}</span>`;

    DOM.style.set(list, {
      display: visible ? 'none' : 'table-cell',
    });

    this.update.listWidth();

    if (!visible && this.options.performance && this.optimize.enabled) {
      this.optimize.attemptRefresh();
    }

    return !visible;
  };

  /**
   * Binds listeners (called on create, show etc.)
   */
  private bind = (): HTMLElement | null => {
    // Unbind previous events first
    this.unbind();
    
    // Make sure we have the necessary elements before binding
    if (!this.data.listDrag) {
      console.error('listDrag element not found');
      return null;
    }
    
    if (!this.container) {
      console.error('container element not found');
      return null;
    }

    // Bind new events
    eventHooks.listen(
      this.data.listDrag,
      'mousedown',
      'galleryListMouseDown',
      (): void => {
        this.data.listDragged = true;

        const windowWidth = window.innerWidth;
        const wrapper: HTMLElement | null = this.container?.querySelector(
            ':scope > div.galleryContent > div.media > div.wrapper'
          );

        /* Set cursors and pointer events */
        DOM.style.set(document.body, {
          cursor: 'w-resize',
        });

        DOM.style.set(wrapper, {
          'pointer-events': 'none',
        });

        if (this.list) {
          DOM.style.set(this.list, {
            'pointer-events': 'none',
          });
        }

        /* Remove `dragged` attribute */
        if (this.data.listDrag) {
          this.data.listDrag.setAttribute('dragged', 'true');
        }

        /* Listens for `mousemove` - this changes the width of the list */
        eventHooks.listen(
          'body > div.rootGallery',
          'mousemove',
          'galleryListMouseMove',
          (event: MouseEvent): void => {
            const x = event.clientX;

            if (x < windowWidth) {
              const width = this.options.list.reverse
                ? x + this.getScrollbarWidth()
                : windowWidth - x;

              requestAnimationFrame((): void => {
                DOM.style.set(this.data.list, {
                  width: `${width}px`,
                });
              });
            }
          },
          {
            onAdd: this.removeOnUnbind,
          }
        );
      },
      {
        onAdd: this.removeOnUnbind,
      }
    );

    eventHooks.listen(
      'body > div.rootGallery',
      'mouseup',
      'galleryListMouseUp',
      (): void => {
        if (this.data.listDragged === true) {
          eventHooks.unlisten('body > div.rootGallery', 'mousemove', 'galleryListMouseMove');

          const wrapper: HTMLElement = this.container.querySelector(
            ':scope > div.galleryContent > div.media > div.wrapper'
          );

          /* Unset cursors and pointer events */
          DOM.style.set(document.body, {
            cursor: '',
          });

          DOM.style.set(wrapper, {
            'pointer-events': 'auto',
          });

          if (this.list) {
            DOM.style.set(this.list, {
              'pointer-events': 'auto',
            });
          }

          /* Remove `dragged` attribute */
          if (this.data.listDrag) {
            this.data.listDrag.removeAttribute('dragged');
          }

          const lw: number = Number.parseInt(this.data.list.style.width.replace(/[^-\d.]/g, ''));

          log('gallery', 'Set list width', lw);

          if (lw > 100) {
            const client = JSON.parse(cookies.get(CookieKey));

            client.gallery.listWidth = lw;

            cookies.set(CookieKey, JSON.stringify(client), {
              sameSite: 'lax',
              expires: 365,
            });

            this.update.listWidth(wrapper);
          }

          this.data.listDragged = false;
        }
      },
      {
        onAdd: this.removeOnUnbind,
      }
    );

    /* Add action events */
    eventHooks.listen(
      'body > div.rootGallery',
      'click',
      'galleryContainerClick',
      (event: MouseEvent) => {
        let eventTarget = event.target as HTMLElement;

        if (
          eventTarget &&
          eventTarget.tagName === 'SPAN' &&
          eventTarget.classList.contains('inheritParentAction')
        ) {
          eventTarget = eventTarget.parentNode as HTMLElement;
        }

        if (eventTarget?.hasAttribute('data-action')) {
          /* Current action */
          const action = eventTarget.getAttribute('data-action').toLowerCase();

          /* Translate action */
          const translate = {
            next: (): void => {
              this.navigate(null, 1);
            },
            previous: (): void => {
              this.navigate(null, -1);
            },
            toggle: (): void => {
              this.toggleList(eventTarget);
            },
            close: (): void => {
              this.show(false);
            },
          };

          /* Call action if present */
          if (translate[action]) {
            translate[action]();
          }
        }
      },
      {
        onAdd: this.removeOnUnbind,
      }
    );

    /* List item click listener */
    eventHooks.listen(
      'body > div.rootGallery > div.galleryContent \
			> div.list table',
      'click',
      'listNavigateClick',
      (event: MouseEvent): void => {
        if ((event.target as HTMLElement).tagName === 'TD') {
          this.navigate(DOM.getIndex((event.target as HTMLElement).closest('tr')));
        } else if ((event.target as HTMLElement).tagName === 'TR') {
          this.navigate(DOM.getIndex(event.target as HTMLElement));
        }
      },
      {
        onAdd: this.removeOnUnbind,
      }
    );

    /* Gallery media click listener */
    eventHooks.listen(
      'body > div.rootGallery > div.galleryContent \
			> div.media',
      'click',
      'mediaClick',
      (event: MouseEvent) => {
        /* Hide gallery if media background is clicked */
        if (!['IMG', 'VIDEO', 'A'].includes((event.target as HTMLElement).tagName)) {
          this.show(false);
        }
      },
      {
        onAdd: this.removeOnUnbind,
      }
    );

    if (this.options.mobile === true) {
      /* Handle swipe events */
      const handler = (event: Event, eventData: EventData): void => {
        switch (eventData.directionX) {
          case 'RIGHT':
            this.navigate(null, -1);
            break;

          case 'LEFT':
            this.navigate(null, 1);
            break;
        }
      };

      /* Create swipe events */
      const swipeInstance = new Swipe({
        element: document.querySelector('body > div.rootGallery'),
        onSwiped: handler,
        mouseTrackingEnabled: true,
      });

      /* Initialize */
      swipeInstance.init();
    }

    /* Scroll navigation listener */
    eventHooks.listen(
      'body > div.rootGallery  > div.galleryContent > \
			div.media',
      ['scroll', 'DOMMouseScroll', 'mousewheel'],
      'galleryScrollNavigate',
      (event: WheelEvent): undefined | boolean => {
        if (this.options.scrollInterval > 0 && this.data.scrollbreak === true) {
          return false;
        }

        this.navigate(null, event.detail > 0 || event.deltaY > 0 ? 1 : -1);

        if (this.options.scrollInterval > 0) {
          this.data.scrollbreak = true;

          setTimeout(() => this.scrollBreak(), this.options.scrollInterval);
        }
      },
      {
        onAdd: this.removeOnUnbind,
      }
    );

    /* Handles `keyup` events in the gallery (well - document, but unbinds on close) */
    eventHooks.listen(
      window,
      'keyup',
      'galleryKeyUp',
      (event: KeyboardEvent): void => {
        this.handleKey(event.code, (prevent: boolean) => {
          if (prevent) {
            event.preventDefault();
          }
        });
      },
      {
        onAdd: this.removeOnUnbind,
      }
    );

    /* Handles `keydown` events */
    eventHooks.listen(
      window,
      'keydown',
      'galleryKeyDown',
      (event: KeyboardEvent): void => {
        if (this.data.keyPrevent.includes(event.code)) {
          event.preventDefault();
        }

        if (event.code === Keys.g) {
          this.show(false);
        }
      },
      {
        onAdd: this.removeOnUnbind,
      }
    );

    /* Dispatches a `bound` event */
    eventHooks.trigger('galleryBound', true);

    return this.container;
  };

  /* Construct gallery top bar items */
  private barConstruct = (bar: HTMLElement): HTMLElement => {
    /* Create `download` button */
    bar.append(
      DOM.new('a', {
        text: this.options.mobile ? 'Save' : 'Download',
        class: 'download',
        download: '',
      })
    );

    if (!this.options.mobile) {
      /* Create `previous` button */
      bar.append(
        DOM.new('span', {
          'data-action': 'previous',
          text: 'Previous',
        })
      );

      /* Create `next` button */
      bar.append(
        DOM.new('span', {
          'data-action': 'next',
          text: 'Next',
        })
      );

      /* Create `list toggle` button */
      const listToggle: HTMLElement = DOM.new('span', {
        'data-action': 'toggle',
        text: 'List',
      });

      listToggle.append(
        DOM.new('span', {
          class: 'inheritParentAction',
          text: this.options.list.show ? '-' : '+',
        })
      );

      /* Create `list toggle` button */
      bar.append(listToggle);
    }

    /* Create `close` button */
    bar.append(
      DOM.new('span', {
        'data-action': 'close',
        text: 'Close',
      })
    );

    return bar;
  };

  /**
   * Creates the gallery elements, populates the table etc.
   */
  private initiate = (callback: (state?: boolean) => void): void => {
    /* Fix body overflow and paddings */
    this.limitBody(true);

    const preview: HTMLElement = document.body.querySelector(':scope > div.preview-container');

    /* Remove any active hover previews just in case */
    if (preview) {
      preview.remove();
    }

    /* Create main container */
    this.container = DOM.new('div', {
      class: 'rootGallery',
    });

    document.body.prepend(this.container);

    /* Create gallery top bar */
    const top: HTMLElement = DOM.new('div', {
      class: 'galleryBar',
    });

    this.container.append(top);

    /* Create left area of top bar */
    top.append(
      DOM.new('div', {
        class: 'galleryBarLeft',
      })
    );

    /* Create right area of top bar */
    top.append(
      this.barConstruct(
        DOM.new('div', {
          class: 'galleryBarRight',
        })
      )
    );

    /* Create content (media) outer container */
    const content: HTMLElement = DOM.new('div', {
      class: `galleryContent${this.options.list.reverse ? ' reversed' : ''}`,
    });

    this.container.append(content);

    const media: HTMLElement = DOM.new('div', {
      class: 'media',
    });

    /* Create list */
    const list: HTMLElement = DOM.new('div', {
      class: 'ns list',
    });

    /* Add to content container, respecting list reverse status */
    content.append(this.options.list.reverse ? list : media);
    content.append(this.options.list.reverse ? media : list);

    /* Create dragable element on list edge */
    this.data.listDrag = DOM.new('div', {
      class: 'drag',
    });

    list.append(this.data.listDrag);

    /* Declare variables */
    this.data.list = list;
    this.data.listDragged = false;

    /* Get user storage */
    const client: TUserStorage = JSON.parse(cookies.get(CookieKey));

    try {
      /* Get gallery list width */
      const width: number = JSON.parse(client.gallery.listWidth.toString().toLowerCase());

      /* If list width is settable */
      if (width && Number.parseInt(width.toString()) > window.innerWidth / 2) {
        client.gallery.listWidth = Math.floor(window.innerWidth / 2);

        cookies.set(CookieKey, JSON.stringify(client), {
          sameSite: 'lax',
          expires: 365,
        });
      }

      if (width) {
        DOM.style.set(this.data.list, {
          width: `${width}px`,
        });
      }
    } catch (e: unknown) {
      client.gallery.listWidth = false;

      cookies.set(CookieKey, JSON.stringify(client), {
        sameSite: 'lax',
        expires: 365,
      });
    }

    /* Create mobile navigation (left & right) */
    if (this.options.mobile === true) {
      const navigateLeft: HTMLElement = DOM.new('div', {
        class: 'screenNavigate navigateLeft',
        'data-action': 'previous',
      });

      const navigateRight: HTMLElement = DOM.new('div', {
        class: 'screenNavigate navigateRight',
        'data-action': 'next',
      });

      navigateLeft.append(DOM.new('span'));
      navigateRight.append(DOM.new('span'));

      content.append(navigateRight, navigateLeft);
    }

    media.append(
      DOM.new('div', {
        class: `wrapper${this.options.fitContent ? ' fill' : ''}`,
      })
    );

    media.append(
      DOM.new('div', {
        class: 'spinner',
      })
    );

    /* Create list table */
    const table = DOM.new('table', {
      cellspacing: '0',
    });

    table.append(DOM.new('tbody'));

    list.append(table);

    /* Add items to list */
    this.populateTable(this.items);

    callback(true);
  };
}
