import type { TExtensionArray } from '../types';

/**
 * Capitalizes the first letter of a string
 * @param input - The string to capitalize
 * @returns The capitalized string
 */
export const capitalize = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

/**
 * Shortens a string to a specified length, adding ellipsis in the middle
 * @param input - The string to shorten
 * @param cutoff - The maximum length of the shortened string (default is 28)
 * @returns The shortened string
 */
export const shortenString = (input: string, cutoff = 28): string => {
  if (input.length > cutoff) {
    return [
      input.substring(0, Math.floor(cutoff / 2 - 2)),
      input.substring(input.length - Math.floor(cutoff / 2 - 2), input.length),
    ].join(' .. ');
  }
    return input;
};

/**
 * Identifies the extension of a URL and categorizes it as image or video
 * @param url - The URL to check
 * @param extensions - An object containing arrays of image and video extensions
 * @returns An array with the extension and its category index, or null if not found
 */
export const identifyExtension = (
  url: string,
  extensions: TExtensionArray = {
    image: [],
    video: [],
  }
): Array<string | number> | null => {
  const extension = url.split('.').pop()?.toLowerCase();

  if (extension && extensions.image.includes(extension)) {
    return [extension, 0];
  }if (extension && extensions.video.includes(extension)) {
    return [extension, 1];
  }

  return null;
};

/**
 * Strips the query string from a URL
 * @param url - The URL to strip
 * @returns The URL without the query string
 */
export const stripUrl = (url: string): string => {
  return !url.includes('?') ? url : url.split('?')[0];
};
